import React, { useContext } from "react";
import { LuPlane } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../Context/ThemeContext";
import MainButton from "../../../../Shared/Components/MainButton";
import { PhotoContext } from "../../../../Context/PhotoContext";
import pic1 from "../../../../assets/imgs/LandingSection/80.jpeg";
import pic2 from "../../../../assets/imgs/LandingSection/20.jpeg";
import pic3 from "../../../../assets/imgs/LandingSection/7.10.jpeg";
import pic4 from "../../../../assets/imgs/LandingSection/7.30.jpeg";

function Landing() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { totalPhotos } = useContext(PhotoContext);
  const lng = localStorage.getItem("vividVisionsLang") || "English";

  return (
    <div className="flex items-center gap-[80px] mb-5 md:mt-5 md:my-20 pt-6 max-md:flex-col-reverse max-md:gap-2 max-md:mb-[40px] ">
      <div className="basis-[50%] ml-auto max-md:mx-auto">
        <div className="md:ml-auto md:grid md:grid-cols-2 md:gap-[20px]">
          <div
            className={`${
              lng === "Arabic" ? "md:mr-auto" : "md:ml-auto"
            } mb-[20px] md:max-w-[236px] md:max-h-[236px] md:my-auto max-md:hidden`}
          >
            <img
              className="w-full h-full r-12 max-md:max-h-[440px] max-md:aspect-square object-cover object-top"
              src={pic1}
              alt=""
            />
          </div>
          <div className={`mb-[20px] md:hidden`}>
            <img
              className="w-full h-full r-12 max-md:max-h-[440px] max-md:aspect-square object-cover object-top"
              src={pic1}
              alt=""
            />
          </div>
          <div className="flex gap-[20px] md:flex-col">
            <div className="basis-[50%] max-md:hidden md:max-w-[230px]">
              <img className="w-full r-12" src={pic2} alt="" />
            </div>
            <div className="basis-[50%] md:hidden md:max-w-[230px]">
              <img className="w-full r-12" src={pic2} alt="" />
            </div>
            <div className="hidden md:block basis-[50%] md:max-w-[450px]">
              <img className="w-full r-12" src={pic4} alt="" />
            </div>
            <div className="block md:hidden basis-[50%] md:max-w-[450px]">
              <img className="w-full h-full r-12" src={pic3} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          theme === "dark" && "text-DarkMainText"
        } basis-[50%] max-md:text-center`}
      >
        <h1 className="f-bold text-[50px] md:leading-[4rem] mb-6 max-md:mb-4 max-xl:f-bold max-xl:text-[45px] max-xl:leading-none 2xl:max-w-[500px] max-md:text-[22px]">
          {t("Turn your photos into stunning wall art.")}
        </h1>
        <div className="max-md:mb-4 max-md:flex max-md:flex-col max-md:items-center">
          <p className="text-[18px] max-md:text-[14px] mb-6 max-md:mb-4 f-semi-bold ">
            {t(
              "Create beautiful walls filled with memories using your favorite photos."
            )}
          </p>
          <div className="hidden md:block md:w-[312px] h-[60px] rounded-[10px] mb-[24px]">
            <MainButton
              link={"/edit-photos"}
              content={
                totalPhotos && totalPhotos > 0
                  ? `${t("Continue Design")} (${totalPhotos})`
                  : t("Start Design")
              }
              className="w-full h-full"
            />
          </div>
          <div className="flex gap-3 items-center">
            <LuPlane size={25} />
            <h1 className="f-bold text-[18px] max-md:text-[14px]">
              {t("Free worldwide shipping!")}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
