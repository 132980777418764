import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa6";
import { PhotoContext } from "../../Context/PhotoContext";
import { switchPhotoItemActivation } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import { useTranslation } from "react-i18next";

const PhotoItem = ({
  className,
  link,
  id,
  onEdit,
  onDelete,
  isActive,
  title,
  description,
  image,
  imageHover,
  rate_value,
  price,
  discount,
  quentity,
  refreshArt,
}) => {
  const { t } = useTranslation();
  const currency = localStorage.getItem("currency") || "USD";
  const { theme } = useTheme();
  const filledStars = [];
  const emptyStars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rate_value) {
      filledStars.push(<FaStar key={i} />);
    } else {
      emptyStars.push(<FaRegStar key={i} />);
    }
  }
  const { dashItem } = useContext(PhotoContext);
  async function toggleActiveStatus(id) {
    try {
      const response = await switchPhotoItemActivation(id);
      // console.log(response);
    } catch (error) {
      console.error("switch photoItem activation err", error);
    }
    refreshArt();
  }
  return (
    <div
      style={{ direction: "ltr" }}
      className={`${className} ${
        dashItem && "overflow-hidden pb-6"
      } z0 flex flex-col rounded-[6px] relative group`}
    >
      {dashItem ? (
        <div className="absolute z20 opacity-0 bg-primary/60 p-2 group-hover:right-5 group-hover:opacity-100 d-300 transition-all flex-col flex gap-2 -right-0 top-5">
          <button
            onClick={() => onDelete(id)}
            className="flex rounded-full justify-center items-center bg-red-600 hover:brightness-90 d-300 p-1 t-white"
          >
            <MdDelete size={25} />
          </button>
          <Link>
            <button
              onClick={() => onEdit(id)}
              className="flex rounded-full justify-center items-center bg-slate-700 hover:bg-slate-800 t-white d-300 p-1"
            >
              <MdEdit size={25} />
            </button>
          </Link>
        </div>
      ) : null}
      <Link className="flex flex-col" to={link}>
        <div className="basis-[80%] border-2 border-[#e4e4e4] rounded-[6px] z0 d-300 shadow-photoItemShadow">
          <img
            className={`${
              imageHover && "group-hover:hidden"
            } o-cover w-full animate-flashing rounded-[6px] z0 d-300`}
            src={image}
            alt=""
          />
          <img
            className={`group-hover:block hidden o-cover w-full animate-flashing rounded-[6px] z0 d-300`}
            src={imageHover}
            alt=""
          />
        </div>
        <div className="basis-[20%] flex flex-col gap-1 pt-2 items-center text-center">
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } text-[14px] max-md:text-[13px] font-[600]`}
          >
            {`${title.substring(0, 25)} ....`}
          </h1>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText/70" : "text-[#777]"
            } text-[12px] max-md:text-[11px] font-[400]`}
          >{`${description.substring(0, 60)} ....`}</h1>
          {/* <div
            className={`${
              theme === "dark" && "text-DarkMainText"
            } flex items-center`}
          >
            {filledStars}
            {emptyStars}
          </div> */}
          <div className="flex justify-center items-center text-[14px] max-md:text-[13px] font-[500] gap-1 w-full ">
            <h3
              className={`${
                theme === "dark" ? "text-DarkMainText/90" : "text-SecondText"
              } f-semi-bold ${discount !== 0 && "line-through"}`}
            >
              {discount
                ? parseFloat(discount).toFixed(2)
                : parseFloat(price).toFixed(2)}{" "}
              {currency}
            </h3>
            {discount !== 0 && (
              <h3
                className={`${
                  theme === "dark" ? "text-[#94e1b5]" : "text-[#0F743B]"
                } text-[12px] max-md:text-[11px]`}
              >
                {discount ? parseFloat(price).toFixed(2) : null} {currency}
              </h3>
            )}
          </div>
          <h1
            className={`${
              quentity === 0 ? "bg-red-500/50" : "bg-green-400/50"
            } text-[12px] max-md:text-[11px] font-[400] w-fit p-1 rounded-[6px]`}
          >
            {quentity === 0
              ? t("Out of stack")
              : `${quentity} ${t("Left in stack")}`}
          </h1>
          <h1
            className={`${
              quentity === 0 ? "bg-[#ae9c81]" : "bg-[#ae9c81]"
            } text-[12px] max-md:text-[11px] t-white font-[400] w-fit p-1 rounded-[1px] px-2`}
          >
            {quentity === 0
              ? t("Limited Stock Ended")
              : `${t("Limited Release")}`}
          </h1>
          {!dashItem ? null : (
            <button
              onClick={() => toggleActiveStatus(id)}
              className={`${
                isActive
                  ? theme === "dark"
                    ? "t-white"
                    : "text-black"
                  : "text-[#777]"
              } absolute bottom-0 right-2 `}
            >
              {isActive ? "Active" : "Not Active"}
            </button>
          )}
        </div>
      </Link>
    </div>
  );
};

export default PhotoItem;


