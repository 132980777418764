import axios from "axios";
import Cookies from "js-cookie";

// export const BASE_URL = "http://127.0.0.1:8000";
// export const BASE_URL = "https://backend.tecrek.com/public";
// export const RETURN_URL = "https://www.vividvisionsprint.com"
export const BASE_URL = "https://vividvisionstest.tecrek.com/public";
export const RETURN_URL = "https://printbent.tecrek.com"
// export const RETURN_URL = "http://localhost:3000"


export const userToken = Cookies.get("userToken");
export const curiousToken = Cookies.get("curiousToken");
const BearerToken = userToken ? userToken : curiousToken;

export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${BearerToken}`,

  },
});


export const adminToken = Cookies.get("adminToken");
const AdminBearerToken = adminToken;

export const apiAdmin = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${AdminBearerToken}`,
  },
});

export const getCurrencyByCountry = async () => {
  try {
    const response = await apiClient.get(`/api/CurrencyByCountry`);
    return response;
  } catch (err) {
    console.error("currency by country err", err);
  }
};

export const whoVisitWebsite = async () => {
  try {
    const response = await apiClient.get(`/api/PrintBent`);
    return response;
  } catch (err) {
    console.error("who visit website err : ", err);
  }
};

export const language = async () => {
  try {
    const response = await apiClient.get(`/api/language`);
    return response;
  } catch (err) {
    console.error("language err : ", err);
  }
};

export const postUrlPage = async (url) => {
  try {
    const response = await apiClient.post(`/api/logPageVisit`, url);
    return response;
  } catch (err) {
    console.error("post url page err : ", err);
  }
};
