import React from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { useTheme } from "../../Context/ThemeContext";

function DashboardOutlet({ title, children }) {
  const { theme, setTheme } = useTheme();
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl f-semi-bold">{title}</h1>
        <div
          onClick={() => {
            theme === "light" ? setTheme("dark") : setTheme("light");
          }}
          className={`${
            theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
          } p-[5px] c-pointer rounded-full`}
        >
          {theme === "dark" ? (
            <MdLightMode size={20} />
          ) : (
            <MdDarkMode size={20} />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3">{children}</div>
    </div>
  );
}

export default DashboardOutlet;
