import { React, useState, useContext, useRef, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { addPhotoItem } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import { toastError } from "../../Shared/Components/ToastNotification/Toast";
// import { useDropzone } from "react-dropzone";

function AddNewPhotoItem({ fetchedCategories, refreshArt }) {
  const fileInputRef = useRef(null);
  const { theme } = useTheme();
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [categories, setCategories] = useState(fetchedCategories);

  const [name, setName] = useState("");
  const [category, setCategory] = useState(fetchedCategories[0].id);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([null, null, null]);
  const [selectedPhotos, setSelectedPhotos] = useState([null, null, null]);
  const { setAddNewItemOpen } = useContext(PopupContext);
  //  const { getRootProps, getInputProps } = useDropzone({
  //    accept: "image/*",
  //    onDrop: (acceptedFiles) => {
  //      if (acceptedFiles.length > 3) {
  //        toastError("You can upload exactly 3 photos.");
  //      } else {
  //        setSelectedFiles(acceptedFiles.slice(0, 3));
  //      }
  //    },
  //    maxFiles: 3,
  //  });
  const handleChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length !== 3) {
      toastError("You can upload exactly 3 photos.");
      // Reset the file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      const firstThreeFiles = files.slice(0, 3);
      const fileUrls = firstThreeFiles.map((file) => URL.createObjectURL(file));
      setSelectedFiles(firstThreeFiles);
      setSelectedPhotos(fileUrls);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      name &&
      description &&
      price &&
      category &&
      selectedFiles[0] &&
      selectedFiles[1] &&
      selectedFiles[2]
    ) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("category_id", category);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("price_after_discount", priceAfterDiscount);

      selectedFiles.forEach((file, index) => {
        if (file) {
          formData.append(`array_ids[${index}][url_photo]`, file);
        }
      });

      try {
        setSpinnerLoad(true);
        const response = await addPhotoItem(formData);
        // console.log(response);
        refreshArt();
      } catch (err) {
        // console.log("add new photo item", err);
      }
      setAddNewItemOpen(false);
      setSpinnerLoad(false);
    } else if (!selectedFiles[1] || !selectedFiles[2]) {
      toastError("You should add 3 photos");
    } else {
      toastError("All fields are required");
    }
  };
  const removePhoto = (index) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    setSelectedPhotos(selectedPhotos.filter((_, i) => i !== index));
  };
  useEffect(() => {
    // console.log("selectedFiles is : ", selectedFiles);
  }, [selectedFiles]);
  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkBoxBg"
      } rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full`}
    >
      <form
        onSubmit={handleSubmit}
        className={`${
          theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-[#f7f7f7]"
        } flex flex-col pb-[15px]`}
      >
        <div
          className={`${
            theme === "dark" ? "bg-DarkBoxBg" : "md:bg-MainBg"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-[18px] leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
        >
          <div
            className={`${
              theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
            } p-[5px] rounded-full`}
          >
            <AiOutlineClose
              className="c-pointer"
              onClick={() => {
                setAddNewItemOpen(false);
              }}
              size={26}
            />
          </div>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
          >
            Add New Item
          </h1>
          <button
            type="submit"
            className={`${
              spinnerLoad && "cursor-default pointer-events-none"
            } flex justify-center items-center h-[52px] text-[18px] c-pointer font-[700] text-PrimaryColor hover:underline d-300`}
          >
            {spinnerLoad ? <ProcessSpinner /> : "Done"}
          </button>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "text-DarkMainText/70 bg-DarkBoxBg"
              : "text-SecondText lg:bg-[#f7f7f7]"
          } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
        >
          <div className="flex flex-col">
            <h1 className="mb-[0.25rem]">Item Name</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Item Name"
            />

            <h1 className="mb-[0.25rem]">Item Description</h1>
            <textarea
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } min-h-[200px] max-h-[200px] resize-none p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Item Description"
            />
            <h1 className="mb-[0.25rem]">Item Category</h1>
            <select
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              placeholder="Select Category"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <option disabled value="">
                Select
              </option>
              {categories &&
                categories.map((cat) => {
                  return (
                    <option className="w-fit" key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  );
                })}
            </select>
            <h1 className="mb-[0.25rem]">Item Price</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Item Price"
            />
            <h1 className="mb-[0.25rem]">Item Price After Discount</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={priceAfterDiscount}
              onChange={(e) => setPriceAfterDiscount(e.target.value)}
              placeholder="Item Price After Discount"
            />
            <div>
              <h1 className="mb-[0.25rem]">Images (Take 3 photos)</h1>
              <input
                ref={fileInputRef}
                className={`p-[0.60rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                type="file"
                accept="image/*"
                onChange={handleChange}
                multiple
              />
            </div>
            <div className="flex flex-nowrap items-center gap-1">
              {selectedPhotos[0] !== null &&
                selectedPhotos.map((photoUrl, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedPhotos ? "block" : "hidden"
                    } basis-1/3 h-[200px] relative`}
                  >
                    <div
                      onClick={() => removePhoto(index)}
                      className={`${
                        selectedPhotos ? "block" : "hidden"
                      } bg-white p-1 rounded-full absolute right-1 top-1 c-pointer`}
                    >
                      <AiOutlineClose />
                    </div>
                    <img
                      className="w-full h-full o-cover"
                      src={photoUrl}
                      alt=""
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewPhotoItem;
