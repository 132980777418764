import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import MainButton from "../../Shared/Components/MainButton";
import { fetchAllOrdersForUser } from "../../Api/Orders";
import { PhotoContext } from "../../Context/PhotoContext";
import Loading from "../../Shared/Components/Loading";
import BreakDiv from "../../Shared/Components/BreakDiv";
import { IoGift, IoPerson } from "react-icons/io5";
import Container from "../../Shared/Components/Container";
import { useTheme } from "../../Context/ThemeContext";
import { useTranslation } from "react-i18next";
import LogInWithCode from "../Components/LogInWithCode";
import { Link, useNavigate } from "react-router-dom";
import CustomPagination from "../../Shared/Components/CustomPagination";

function MyOrders() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const {
    loading,
    setLoading,
    totalPages,
    setTotalPages,
    itemPerPage,
    setItemPerPage,
    pageNumber,
    setPageNumber,
  } = useContext(PhotoContext);
  const [orders, setOrders] = useState([]);
  const currency = localStorage.getItem("currency");

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };
  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const ordersData = await fetchAllOrdersForUser(pageNumber);
      // console.log(ordersData);
      setOrders(ordersData.data);
      setTotalPages(ordersData.last_page);
      setItemPerPage(ordersData.per_page);
      setPageNumber(ordersData.current_page);
      setLoading(false);
    } catch (err) {
      console.error("fetch all orders err", err);
    }
  };
  useEffect(() => {
    fetchOrdersData();
  }, [setLoading, pageNumber]);
  return (
    <div className={`${theme === "dark" && "bg-DarkMainBg"}`}>
      <Navbar sticky={true} />
      <Container>
        {loading ? (
          <Loading />
        ) : orders && orders.length < 1 ? (
          <div
            className={`${
              theme === "dark" && "bg-DarkMainBg"
            } -translate-y-[80px] flex justify-center items-center h-screen`}
          >
            <div
              className={`${
                theme === "dark"
                  ? "md:bg-DarkBoxBg max-md:bg-DarkMainBg md:shadow-registerWayBoxShadowDM"
                  : "md:bg-[#fff] max-md:bg-MainBg md:shadow-registerWayBoxShadow"
              } w-[650px] p-[100px] text-center rounded-[5px] max-md:p-0`}
            >
              <h1
                className={`${
                  theme === "dark" ? "text-DarkMainText" : "text-[#333333]"
                } text-[20px] font-[700] mb-[15px]`}
              >
                {t("It looks like you haven't placed any orders yet.")}
              </h1>
              <div className="w-fit mx-auto">
                <MainButton
                  link="/edit-photos"
                  content={t("Select some pictures")}
                  className="mx-auto  rounded-[4px] text-[16px] font-[700] h-[51px] py-[6px] px-[16px] mb-[24px] buttonBoxShadow"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="min-h-screen px-10 max-md:px-1 pb-10 w-full overflow-y-auto mt-10">
            <h1 className="text-2xl f-semi-bold mb-5">{t("Orders")}</h1>
            {orders &&
              orders.map((order) => (
                <div className="" key={order.order_id}>
                  {orders && orders.length > 1 && (
                    <BreakDiv className="my-[20px]" />
                  )}
                  <Link
                    to={`/order-details/${order.number}`}
                    className={`${
                      theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
                    } flex flex-col rounded-xl mb-5 relative`}
                  >
                    <div className="flex justify-between items-center border-b border-[#ebe7e7] py-4 px-1">
                      <div className="flex items-center gap-1">
                        <h1 className="text-[18px] f-semi-bold max-md:text-[16px]">
                          {t("Order ID")}:
                        </h1>
                        <h2
                          className={`${
                            theme === "dark"
                              ? "text-DarkMainText/70"
                              : "text-[#777]"
                          } text-[18px] max-md:text-[16px]`}
                        >
                          #{order?.number}
                        </h2>
                      </div>
                      <div className="flex items-center gap-4">
                        <div
                          className={`${
                            theme === "dark"
                              ? "md:bg-DarkBoxBg shadow-registerWayBoxShadowDM"
                              : "md:bg-[#fff] shadow-registerWayBoxShadow"
                          } flex items-center gap-2 text-[18px] max-md:text-[16px] max-md:absolute max-md:top-[40px] max-md:left-[50%] max-md:-translate-x-[50%] max-md:py-[2px] max-md:px-4 max-md:border-[2px] max-md:rounded-2xl`}
                        >
                          <h1
                            className={`${
                              theme === "dark"
                                ? "text-DarkMainText/70"
                                : "text-[#777]"
                            }`}
                          >
                            {t("Status")}:
                          </h1>
                          <h2 className="text-PrimaryColor">{order?.status}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-nowrap justify-between py-4 px-1">
                      <div className="flex flex-col">
                        <div className="flex md:items-center gap-1 py-1 max-md:flex-col">
                          <h1 className="f-semi-bold max-w-full">
                            {t("Earliest Delivery Date")}:
                          </h1>
                          <h2
                            className={`${
                              theme === "dark"
                                ? "text-DarkMainText/70"
                                : "text-[#777]"
                            }`}
                          >
                            {order?.minimum_delivery_date}
                          </h2>
                        </div>
                        <div className="flex md:items-center gap-1 py-1 max-md:flex-col">
                          <h1 className="f-semi-bold max-w-full">
                            {t("Latest Delivery Date")}:
                          </h1>
                          <h2
                            className={`${
                              theme === "dark"
                                ? "text-DarkMainText/70"
                                : "text-[#777]"
                            }`}
                          >
                            {order?.max_delivery_date}
                          </h2>
                        </div>
                        <div className="flex md:items-center gap-1 py-1 max-md:flex-col">
                          <h1 className="f-semi-bold max-w-full">
                            {t("Total")}:
                          </h1>
                          <h2
                            className={`${
                              theme === "dark"
                                ? "text-DarkMainText/70"
                                : "text-[#777]"
                            }`}
                          >
                            {order?.total_price} {currency}
                          </h2>
                        </div>
                        {order.gift_note !== "0" && (
                          <div className="flex md:items-center gap-1 py-1 max-md:flex-col">
                            <h1 className="f-semi-bold max-w-full">
                              {t("The gift note")}:
                            </h1>
                            <h2
                              className={`${
                                theme === "dark"
                                  ? "text-DarkMainText/70"
                                  : "text-[#777]"
                              }`}
                            >
                              {order.gift_note}
                            </h2>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            <div className="mt-5">
              <CustomPagination
                totalPages={totalPages}
                currentPage={pageNumber}
                itemPerPage={itemPerPage}
                handlePageClick={handlePageClick}
              />
            </div>
          </div>
        )}
      </Container>
      <LogInWithCode />
    </div>
  );
}

export default MyOrders;
