import { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import {
  checkOutOrder,
  fetchAddress,
  fetchCountries,
  fetchRegionsByCountry,
  postAddress,
  updateAddress,
} from "../../Api/Editphotos";
import { useTheme } from "../../Context/ThemeContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import AddressInputSkeleton from "./Skeleton/AddressInputSkeleton";
import { useTranslation } from "react-i18next";
import Popup from "../../Shared/Components/Popup";
import Skeleton from "react-loading-skeleton";
import { toastError } from "../../Shared/Components/ToastNotification/Toast";
function AddAddress() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [keyType, setKeyType] = useState("");
  const [cityStatus, setCityStatus] = useState("");
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const { addressOpen, setAddressOpen } = useContext(PopupContext);
  const {
    address,
    setAddress,
    regionType,
    setRegionType,
    cartItemsInfo,
    setCartItemsInfo,
    setCartItemsInfoUpdate,
    setCheckoutDataLoading,
  } = useContext(PhotoContext);

  const handleChange = async (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
    if (e.target.name === "country_id") {
      // console.log("Setting country_id to:", e.target.value);
      await fetchRegionsData(e.target.value);
      // Ensure countries data is loaded
      if (countries.length > 0) {
        // Find the selected country object
        const selectedCountry = countries.find(
          (country) => country.id === Number(e.target.value)
        );
        // console.log("Selected Country:", selectedCountry);
        // console.log("Country IDs:",   countries.map((country) => country.id));
        // Set the regionType based on the selected country's type
        setRegionType(selectedCountry ? selectedCountry.type : "");
        setKeyType(selectedCountry ? selectedCountry.key : "");
        setCityStatus(selectedCountry ? selectedCountry.city : "");
        // console.log("Region Type:", regionType);
        // console.log("Key Type:", keyType);
      } else {
        // console.log("Countries data not loaded yet.");
      }
    }
  };
  // a new state to track if the countries data is being loaded
  const [isCountriesLoading, setIsCountriesLoading] = useState(true);

  const fetchCountriesData = async () => {
    setIsCountriesLoading(true);
    try {
      const response = await fetchCountries();
      // console.log("Fetched Countries:", response.data);
      setCountries(response.data);
    } catch (err) {
      console.error("fetch countries err", err);
    } finally {
      setIsCountriesLoading(false);
    }
  };

  // a new state to track if the countries data is being loaded
  const [isRegionsLoading, setIsRegionsLoading] = useState(true);

  const fetchRegionsData = async (country_id) => {
    setIsRegionsLoading(true);
    try {
      const response = await fetchRegionsByCountry(country_id);
      // console.log(response.data);
      setRegions(response.data);
      setAddress((prev) => ({ ...prev, region_id: response.data[0].id }));
    } catch (err) {
      console.error("fetch regions err", err);
    } finally {
      setIsRegionsLoading(false);
    }
  };

  const [dataLoading, setDataLoading] = useState(false);
  const getAddressData = async () => {
    try {
      setDataLoading(true);
      const response = await fetchAddress(cartItemsInfo.addressId);
      // console.log(response);
    } catch (err) {
      console.error("get address data err : ", err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    cartItemsInfo.addressId && getAddressData();
    fetchCountriesData();
  }, []);
  useEffect(() => {
    if (address.country_id) {
      fetchRegionsData(address.country_id);
    }
  }, [address.country_id]);

  const giftNote = localStorage.getItem("giftNote");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidEmail =
      /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/i.test(
        address.email
      );

    if (isValidEmail) {
      try {
        setSpinnerLoad(true);
        const response = cartItemsInfo.addressId
          ? await updateAddress(cartItemsInfo.addressId, address)
          : await postAddress(address);

        if (response.success === "true") {
          setCartItemsInfo((prev) => ({
            ...prev,
            addressId: response?.data?.id,
          }));
          localStorage.setItem("fullName", response?.data?.full_name);
          setCheckoutDataLoading(true);
          const formData = new FormData();
          formData.append(`address_id`, response.data.id);
          if (cartItemsInfo.orderId) {
            formData.append("order_id", cartItemsInfo.orderId);
          }
          if (cartItemsInfo.discountCode) {
            formData.append(`code`, cartItemsInfo.discountCode);
          }
          if (giftNote) {
            formData.append(`gift_note`, giftNote.trim());
          }

          try {
            const response = await checkOutOrder(formData);
            // console.log(response);
            localStorage.setItem("cartId", response.data.cart_id);
            if (response.success === "true") {
              setAddressOpen(false);
              setCartItemsInfoUpdate((prev) => prev + 1);
              // window.location.reload();
              // setCheckoutOpen(true);
              // navigate(0, { replace: true });
            }
          } catch (err) {
            console.error("check out order err", err);
          }
        }
      } catch (err) {
        console.error("post address information err", err);
      } finally {
        setSpinnerLoad(false);
      }
    } else {
      toastError(t("Please enter a valid email"));
    }
  };

  return (
    <Popup
      toggleSwitch={addressOpen}
      children={
        <div
          className={`${
            theme === "dark" && "bg-DarkMainBg"
          } rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:max-h-full md:min-w-[600px] w-full`}
        >
          <form
            onSubmit={handleSubmit}
            className={`${
              theme === "dark" ? "bg-DarkMainBg" : "bg-[#f7f7f7]"
            } flex flex-col pb-[15px]`}
          >
            <div
              className={`${
                theme === "dark" ? "bg-DarkMainBg" : "md:bg-MainBg"
              } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-[18px] leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
            >
              <div
                className={`${
                  theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
                } p-[5px] rounded-full d-300`}
              >
                <AiOutlineClose
                  className="c-pointer"
                  onClick={() => {
                    setAddressOpen(false);
                  }}
                  size={26}
                />
              </div>
              <h1
                className={`${
                  theme === "dark" ? "text-DarkMainText" : "text-MainText"
                } md:py-[27px]`}
              >
                {t("Add Address")}
              </h1>
              {dataLoading ? (
                <div className="w-[93px] h-[52px]">
                  <Skeleton className="h-full" />
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex justify-center items-center min-w-[93px] h-[52px] text-[18px] c-pointer font-[700] text-PrimaryColor hover:underline d-300"
                >
                  {spinnerLoad ? <ProcessSpinner /> : t("Completed")}
                </button>
              )}
            </div>
            <div
              className={`${
                theme === "dark"
                  ? "text-DarkMainText/70 lg:bg-DarkMainBg"
                  : "text-SecondText lg:bg-[#f7f7f7]"
              } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px]`}
            >
              <div className="flex flex-col">
                {dataLoading ? (
                  <AddressInputSkeleton />
                ) : (
                  <>
                    <h1 className="mb-[0.25rem]">{t("Email")}</h1>
                    <input
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                      type="text"
                      placeholder={t("Your Email")}
                      value={address.email}
                      name="email"
                      onChange={handleChange}
                    />
                  </>
                )}
                {dataLoading ? (
                  <AddressInputSkeleton />
                ) : (
                  <>
                    <h1 className="mb-[0.25rem]">{t("Full name")}</h1>
                    <input
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                      type="text"
                      placeholder={t("First and last name")}
                      value={address.full_name}
                      name="full_name"
                      onChange={handleChange}
                    />
                  </>
                )}
                {isCountriesLoading || dataLoading ? (
                  <AddressInputSkeleton />
                ) : (
                  <>
                    <h1 className="mb-[0.25rem]">{t("Country")}</h1>
                    <select
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                      type="text"
                      placeholder={t("Select Country")}
                      value={address.country_id}
                      name="country_id"
                      onChange={handleChange}
                    >
                      <option className="w-fit" disabled value="">
                        {t("Select")}
                      </option>
                      {countries.map((country) => {
                        return (
                          <option
                            className="w-fit"
                            key={country.id}
                            value={country.id}
                          >
                            {country.name}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}

                {isRegionsLoading || dataLoading ? (
                  <AddressInputSkeleton />
                ) : (
                  address.country_id &&
                  regionType !== "0" && (
                    <div className="w-full">
                      <h1 className="mb-[0.25rem]">{t(regionType)}</h1>
                      <select
                        className={`${
                          theme === "dark"
                            ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                            : "bg-white border-[#e6e6e6] focus:border-SecondText"
                        } min-w-full p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                        type="text"
                        placeholder={t("Select Region")}
                        value={address.region_id}
                        name="region_id"
                        onChange={handleChange}
                      >
                        <option disabled value="">
                          {t("Select")}
                        </option>
                        {regions?.map((region) => {
                          return (
                            <option
                              className="w-fit"
                              key={region.id}
                              value={region.id}
                            >
                              {region.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )
                )}
                {address.country_id &&
                  cityStatus !== 0 &&
                  (dataLoading ? (
                    <AddressInputSkeleton />
                  ) : (
                    <div className="w-full">
                      <h1 className="mb-[0.25rem]">{t("City")}</h1>
                      <input
                        className={`${
                          theme === "dark"
                            ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                            : "bg-white border-[#e6e6e6] focus:border-SecondText"
                        } min-w-full p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                        type="text"
                        placeholder={t("Your City")}
                        value={address.city !== "0" ? address.city : ""}
                        name="city"
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                {dataLoading ? (
                  <AddressInputSkeleton />
                ) : (
                  <>
                    <h1 className="mb-[0.25rem]">{t("Address line")} 1</h1>
                    <input
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                      type="text"
                      placeholder={t("Street address")}
                      value={
                        address.address_line_1 !== "0"
                          ? address.address_line_1
                          : ""
                      }
                      name="address_line_1"
                      onChange={handleChange}
                    />
                  </>
                )}
                {dataLoading ? (
                  <AddressInputSkeleton />
                ) : (
                  <>
                    <h1 className="mb-[0.25rem]">{t("Address line")} 2</h1>
                    <input
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                      type="text"
                      placeholder={t("Additional address")}
                      value={
                        address.address_line_2 !== "0"
                          ? address.address_line_2
                          : ""
                      }
                      name="address_line_2"
                      onChange={handleChange}
                    />
                  </>
                )}
                {address.country_id && (
                  <>
                    {keyType === "zip_code" && (
                      <div>
                        <h1 className="mb-[0.25rem]">{t("Zip Code")}</h1>
                        <input
                          className={`${
                            theme === "dark"
                              ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                              : "bg-white border-[#e6e6e6] focus:border-SecondText"
                          } min-w-full p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                          type="number"
                          placeholder={t("Zip Code")}
                          value={address.zip !== 0 ? address.zip : ""}
                          name="zip"
                          onChange={handleChange}
                        />
                      </div>
                    )}
                    {keyType === "postcode" && (
                      <div>
                        <h1 className="mb-[0.25rem]">{t("Postal Code")}</h1>
                        <input
                          className={`${
                            theme === "dark"
                              ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                              : "bg-white border-[#e6e6e6] focus:border-SecondText"
                          } min-w-full p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                          type="number"
                          placeholder={t("Postal Code")}
                          value={
                            address.postcode !== "0" ? address.postcode : ""
                          }
                          name="postcode"
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </>
                )}
                {dataLoading ? (
                  <AddressInputSkeleton />
                ) : (
                  <>
                    <h1 className="mb-[0.25rem]">{t("Phone number")}</h1>
                    <input
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[50.56px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
                      type="text"
                      placeholder="ex: +1 954 654 6544"
                      value={
                        address.contact_number !== "0"
                          ? address.contact_number
                          : ""
                      }
                      pattern="^(\+?\d{1,4}[\s-]?)?(\d{6,14})$"
                      name="contact_number"
                      onChange={handleChange}
                    />
                  </>
                )}
                <h1
                  className={`${
                    theme === "dark" ? "text-DarkMainText" : "text-MainText"
                  } pt-[8px] text-[14px] opacity-70 leading-[16px] max-w-[275px] -tracking-[0.1px]`}
                >
                  {t(
                    "Your phone number will only be used if we need to reach you regarding delivery."
                  )}
                </h1>
              </div>
            </div>
          </form>
        </div>
      }
      overLayClassName="z40 bg-transparent"
      className={`fixed z50 m-[20px] rounded-[20px] max-md:rounded-none checkoutBoxShadow top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] d-300 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 max-md:m-0 max-md:h-screen max-md:w-full`}
      onClose={() => setAddressOpen(false)}
    />
  );
}

export default AddAddress;
