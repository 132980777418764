import React, { useContext } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTheme } from "../../Context/ThemeContext";

function CategoryItem({
  id,
  image,
  name,
  onClick,
  onEdit,
  onDelete,
  homeMode,
}) {
  const { theme } = useTheme();
  const { dashItem, categoryId } = useContext(PhotoContext);

  return (
    <div className="flex flex-col items-center">
      <div
        className={`${
          parseFloat(id) === parseFloat(categoryId) && !homeMode
            ? "border-PrimaryColor"
            : "border-MainText hover:border-PrimaryColor"
        } border-[2px] p-[2px] relative c-pointer w-[80px] h-[80px] max-md:w-[60px] max-md:h-[60px] group d-300 text-[#472c35] font-[700] text-center mx-[14px] text-[15px] rounded-full`}
      >
        {dashItem && (
          <div className="absolute -z0 opacity-0 bg-primary/60 p-2 rounded-xl group-hover:opacity-100 group-hover:z20 d-300 transition-all flex-col flex gap-2 -right-8 top-0">
            <button
              onClick={() => onDelete(id)}
              className={` flex rounded-full justify-center items-center bg-red-600 hover:brightness-90 d-300 p-1  t-white`}
            >
              <MdDelete size={15} />
            </button>
            <button
              onClick={() => onEdit(id)}
              className="flex rounded-full justify-center items-center bg-slate-700 hover:bg-slate-800 t-white d-300 p-1 "
            >
              <MdEdit size={15} />
            </button>
          </div>
        )}
        <div onClick={onClick} className="w-full h-full">
          <img
            className="w-full h-full o-cover rounded-full"
            src={image}
            alt=""
          />
        </div>
      </div>
      <h1
        onClick={onClick}
        className={`${
          parseFloat(id) === parseFloat(categoryId) && !homeMode
            ? "text-PrimaryColor"
            : theme === "dark"
            ? "text-DarkMainText/70"
            : "text-SecondText"
        } font-[500] text-center text-[14px] max-md:text-[10px]`}
      >
        {name}
      </h1>
    </div>
  );
}

export default CategoryItem;
