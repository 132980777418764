import { toastError } from "../Shared/Components/ToastNotification/Toast";
import { apiAdmin } from "./api";

// Admin
export const login = async (email, password) => {
  try {
    const response = await apiAdmin.post(`/api/loginAdmin`, {
      email: email,
      password: password,
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("admin login err : ", error.response);
    toastError(error.response.data.errors[0]);
  }
};

// Dashboard

// Users
export const fetchUsers = async (page,query) => {
  try {
    const response = await apiAdmin.get(`/api/users?page=${page}&search=${query}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching users : ", error);
  }
};

export const fetchUserById = async (user_id,page) => {
  try {
    const response = await apiAdmin.get(`/api/userById?user_id=${user_id}&page=${page}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching user by id err : ", error);
  }
};

export const updateUserType = async (id, userType) => {
  try {
    const response = await apiAdmin.post(`/api/updateUserType?id=${id}`, {
      user_type_id: userType,
    });
    return response;
  } catch (error) {
    console.error("user updating type err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const switchUserActive = async (id) => {
  try {
    const response = await apiAdmin.post(`/api/UpdateUserActive?id=${id}`);
    return response;
  } catch (error) {
    console.error("user activate switch err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

// Arts

export const fetchCategories = async () => {
  try {
    const response = await apiAdmin.get(`/api/Category`);
    return response.data.data;
  } catch (error) {
    console.error("fetch category err : ", error);
  }
};

export const addCategory = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/Category`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("add category err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const updateCategory = async (id, data) => {
  try {
    const response = await apiAdmin.post(
      `/api/Category/update?id=${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("update category err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await apiAdmin.delete(`/api/Category/delete?id=${id}`);
    return response;
  } catch (error) {
    console.error("category deleting err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const fetchPhotos = async (pageNumber = 1) => {
  try {
    const response = await apiAdmin.get(`/api/PictureAdmin?page=${pageNumber}`);
    return response.data.data;
  } catch (error) {
    console.error("fetch photos err : ", error);
  }
};

export const fetchPhotosByCategoryId = async (categoryId,page) => {
  try {
    const response = await apiAdmin.get(
      `/api/PictureByCategory?category_id=${categoryId}&page=${page}`
    );
    return response.data.data;
  } catch (error) {
    console.error("fetch photos by category err : ", error);
  }
};

export const switchPhotoItemActivation = async (id) => {
  try {
    const response = await apiAdmin.post(`/api/UpdatePictureStatus?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("switch photoItem activation err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const addPhotoItem = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/Picture`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("add photoItem err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const updatePhotoItem = async (id, data) => {
  try {
    const response = await apiAdmin.post(`/api/Picture/update?id=${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("update photoItem err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const deletePhotoItem = async (id) => {
  try {
    const response = await apiAdmin.delete(`/api/Picture/delete?id=${id}`);
    return response;
  } catch (error) {
    console.error("photoItem deleting err : ", error);
    toastError(error.response.data.errors[0]);
  }
};
//   sizes

export const fetchSizes = async (page) => {
  try {
    const response = await apiAdmin.get(`/api/size?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("fetch category err : ", error);
  }
};

export const addNewSize = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/size`, data);
    return response;
  } catch (error) {
    console.error("new size adding err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const updateSize = async (id, data) => {
  try {
    const response = await apiAdmin.post(
      `/api/size/update?id=${id}`,
      data
    );
    return response;
  } catch (error) {
    console.error("size updating err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

export const deleteSize = async (id) => {
  try {
    const response = await apiAdmin.delete(`/api/size/delete?id=${id}`);
    return response;
  } catch (error) {
    console.error("size deleting err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

// orders

export const fetchOrders = async (status_id,endDate,startDate,page) => {
  try {
    const response = await apiAdmin.get(`/api/getAllOrder?status_id=${status_id}&endDate=${endDate}&startDate=${startDate}&page=${page}`);
    return response.data;
  } catch (error) {
    console.error("fetch orders err : ", error);
  }
};

export const filterOrders = async (statusId) => {
  try {
    const response = await apiAdmin.get(
      `/api/filterOrder?status_id=${statusId}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch filter orders err : ", error);
  }
};

export const sortOrders = async (firstDate, endDate) => {
  try {
    const response = await apiAdmin.get(
      `/api/SortOrder?startDate=${firstDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch sorted orders err : ", error);
  }
};

export const fetchOrderInfo = async (id) => {
  try {
    const response = await apiAdmin.get(`/api/OrderDetails?order_id=${id}`);
    return response.data;
  } catch (error) {
    console.error("fetch order details err : ", error);
  }
};

export const updateOrderStatus = async (id, status) => {
  try {
    const response = await apiAdmin.post(
      `/api/UpdateOrderStatus?order_id=${id}`,
      status
    );
    return response;
  } catch (error) {
    console.error("update status order details err : ", error);
    toastError(error.response.data.errors[0]);
  }
};

// reports

export const fetchReports = async (firstDate, endDate) => {
  try {
    const response = await apiAdmin.get(
      `/api/report?start_date=${firstDate}&end_date=${endDate}`,
      { responseType: "arraybuffer" }
    );
    return response;
  } catch (error) {
    console.error("fetch reports err : ", error);
  }
};

export const fetchTecrekFoundedCountries = async () => {
  try {
    const response = await apiAdmin.get(`/api/getCountry-tecrek`);
    return response.data;
  } catch (error) {
    console.error("fetch founded countries err : ", error);
  }
};

export const fetchTecrekVisitors = async (page,country_name) => {
  try {
    const response = await apiAdmin.get(`/api/DeviceInfo?page=${page}&country_name=${country_name}`);
    return response.data;
  } catch (error) {
    console.error("fetch tecrek visitors err : ", error);
  }
};

export const fetchVividVisionsFoundedCountries = async () => {
  try {
    const response = await apiAdmin.get(`/api/getCountry`);
    return response.data;
  } catch (error) {
    console.error("fetch founded countries err : ", error);
  }
};

export const fetchVividVisionsVisitors = async (page,country_name) => {
  try {
    const response = await apiAdmin.get(`/api/DeviceInfoPrintBent?page=${page}&country_name=${country_name}`);
    return response.data;
  } catch (error) {
    console.error("fetch vivid visions visitors err : ", error);
  }
};

// tracktions
export const fetchUsersActivities = async (page,query) => {
  try {
    const response = await apiAdmin.get(`/api/getUsersWithActivities?page=${page}&activity_search=${query}`);
    return response.data;
  } catch (error) {
    console.error("fetch users activities err : ", error);
  }
};

export const fetchActivitiesByUserId = async (user_id) => {
  try {
    const response = await apiAdmin.get(`/api/getUserActivities?user_id=${user_id}`);
    return response.data;
  } catch (error) {
    console.error("fetch activities by user id err : ", error);
  }
};

// Ads 
export const postAds = async (data) => {
  const formData = new FormData();
  data.forEach((item, index) => {
    // console.log(item)
      formData.append(`array[${index}][id]`, item.id);
      formData.append(`array[${index}][title_en]`, item.title_en);
      formData.append(`array[${index}][title_gr]`, item.title_gr);
      formData.append(`array[${index}][description_en]`, item.description_en);
      formData.append(`array[${index}][description_gr]`, item.description_gr);
    });
 
  try {
    const response = await apiAdmin.post(`/api/ads`, formData)
return response.data;
  } catch (error) {
    console.error("post ads err : ", error);
  }
}

// AccessTokens 
export const fetchAccessTokens = async (page,query) => {
  try {
    const response = await apiAdmin.get(`/api/tokens?page=${page}&search=${query}`);
    return response.data;
  } catch (error) {
    console.error("fetch access tokens err : ", error);
  }
};

export const fetchAccessTokenByUserId = async (user_id,page) => {
  try {
    const response = await apiAdmin.get(`/api/tokens?user_id=${user_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.error("fetch access token by id err : ", error);
  }
};