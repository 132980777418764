import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function Popup({
  toggleSwitch,
  onClose,
  className,
  children,
  overLayClassName,
}) {
  const { theme } = useTheme();
  return (
    <>
      {toggleSwitch && (
        <>
          <div
            className={`${
              theme === "dark"
                ? "bg-DarkMainBg text-DarkMainText"
                : "text-MainText bg-MainBg"
            } ${className} d-300 transition-all fixed animate-flashing`}
          >
            {children}
          </div>
          <div
            onClick={onClose}
            className={`${overLayClassName} fixed top-0 left-0 bg-black/50 w-screen h-screen animate-flashing`}
          ></div>
        </>
      )}
    </>
  );
}

export default Popup;
