import React, { useState, useCallback, useContext } from "react";
import Cropper from "react-easy-crop";
import MainButton from "../../Shared/Components/MainButton";
import { useTranslation } from "react-i18next";
import { PopupContext } from "../../Context/PopupContext";

function CropPhoto({ photo, onCroppedImageUrl }) {
  console.log("photo is : ", photo.size);
  const { setCropOpen } = useContext(PopupContext);
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1 / 1); // Default to square aspect ratio
  const [isRectangular, setIsRectangular] = useState(false); // Track if the current aspect is rectangular
  const [isVertical, setIsVertical] = useState(false); // Track if the rectangular aspect is vertical

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const toggleAspectRatio = () => {
    if (aspectRatio === 1 / 1) {
      // Switch to rectangular aspect ratio
      setIsRectangular(true);
      setAspectRatio(isVertical ? 3 / 4 : 4 / 3); // Set aspect ratio based on orientation
    } else {
      // Switch back to square
      setIsRectangular(false);
      setAspectRatio(1 / 1);
    }
  };

  const toggleOrientation = () => {
    if (isRectangular) {
      setIsVertical(!isVertical); // Toggle between vertical and horizontal
      setAspectRatio(isVertical ? 4 / 3 : 3 / 4); // Adjust the aspect ratio accordingly
    }
  };

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imageSrc;
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      img,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return canvas.toDataURL("image/png");
  };

  const dataURLtoFile = (dataUrl, fileName) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  const handleSave = useCallback(async () => {
    try {
      const imageUrl = await getCroppedImg(photo.url_photo, croppedAreaPixels);
      const file = dataURLtoFile(imageUrl, "cropped-image.png");

      // console.log("File is : ", file);
      if (onCroppedImageUrl) {
        onCroppedImageUrl(file); // Send the URL if needed
      }
      // You can also send the 'file' wherever it's needed
    } catch (e) {
      console.error(e);
    } finally {
      setCropOpen(false);
    }
  }, [croppedAreaPixels, photo.url_photo, onCroppedImageUrl, setCropOpen]);

  return (
    <div className="relative flex flex-col gap-2 rounded-[20px] max-md:rounded-none">
      {/* Cropper area */}
      <div className="fixed w-full h-[80%] top-0 left-0">
        <Cropper
          image={photo.url_photo}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatio}
          objectFit="contain"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      {/* Control panel */}
      <div className="flex flex-col items-center gap-3 py-2 fixed bottom-0 left-[50%] -translate-x-[50%] w-full max-w-[500px]">
        {/* Zoom Slider */}
        <div className="w-full px-4">
          <label>{t("Zoom")}</label>
          <input
            type="range"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
            className="w-full"
          />
        </div>

        {/* Aspect Ratio Toggle */}
        <MainButton
          className="rounded-[5px] px-2 py-1 h-[32px]"
          content={
            aspectRatio === 1 / 1
              ? t("Switch to Rectangular")
              : t("Switch to Square")
          }
          onClick={toggleAspectRatio}
        />

        {/* Orientation Toggle (for rectangular only) */}
        {isRectangular && (
          <MainButton
            className="rounded-[5px] px-2 py-1 h-[32px]"
            content={
              isVertical ? t("Switch to Horizontal") : t("Switch to Vertical")
            }
            onClick={toggleOrientation}
          />
        )}

        {/* Save Button */}
        <MainButton
          className="rounded-[5px] px-2 py-1 h-[32px]"
          content={t("Crop and Save")}
          onClick={handleSave}
        />
        <MainButton
          className="rounded-[5px] px-2 py-1 h-[32px]"
          content={t("Cancel")}
          onClick={() => setCropOpen(false)}
        />
      </div>
    </div>
  );
}

export default CropPhoto;
