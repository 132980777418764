import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  materialIcon,
  matIcon,
  sizeIcon,
  effectIcon,
  deleteIcon,
  cropIcon,
} from "../../assets/imgs/SvgImgs";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import Popup from "../../Shared/Components/Popup";
import MaterialList from "../Components/MaterialList";
import SizeList from "../Components/SizeList";
import EffectList from "../Components/EffectList";
import MatList from "../Components/MatList";
import { useTranslation } from "react-i18next";
import {
  addPhotoToCart,
  fetchEditPhotosByPhotoId,
  deletePhotoFromCart,
} from "../../Api/Editphotos";
import { useTheme } from "../../Context/ThemeContext";
import CropPhoto from "../Components/CropPhoto";
import { FaExclamationCircle } from "react-icons/fa";
import Loading from "../../Shared/Components/Loading";
import MakeDecision from "../../Shared/Components/MakeDecision";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function EditOnePhoto() {
  const [activePopup, setActivePopup] = useState(null);
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    loading,
    setLoading,
    updatePhotoArray,
    setUpdatePhotoArray,
    selectedMaterial,
    setSelectedMaterial,
    selectedSize,
    setSelectedSize,
    selectedEffect,
    setSelectedEffect,
    selectedMat,
    setSelectedMat,
    fetchMaterialsData,
    fetchSizesData,
    fetchEffectsData,
    fetchMatsData,
    currentMaterial,
    logNavigation,
  } = useContext(PhotoContext);
  // Find the photo that matches the given id
  const { id } = useParams();
  const navigate = useNavigate();

  const [photo, setPhoto] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [size, setSize] = useState();

  const getPhotoData = async () => {
    try {
      initialLoading && setLoading(true);
      const response = await fetchEditPhotosByPhotoId(id);
      //console.log(response);
      setPhoto(response.data);
      setSize(
        `size${response.data.size.length_cm}x${response.data.size.width_cm}`
      );
      setSelectedMaterial(response.data.material_id);
      setSelectedSize(response.data.size_id);
      setSelectedEffect(response.data.effect_id);
      setSelectedMat(response.data.mat_id);
      if (response.success === "true") setInitialLoading(false);
    } catch (err) {
      console.error(`get photo ${id} data err : `, err);
    } finally {
      setLoading(false);
    }
  };
  const handleOpenPopup = (popupKey) => {
    if (activePopup === popupKey) {
      setActivePopup(null); // Toggle functionality: Click again to close the same popup
    } else {
      setActivePopup(popupKey);
    }
  };

  const addPhoto = async (url, nav) => {
    const requestBody = [
      {
        url_photo: url,
        material_id: selectedMaterial,
        size_id: selectedSize,
        effect_id: selectedEffect,
        mat_id: selectedMat,
      },
    ];
    try {
      const response = await addPhotoToCart(requestBody);
      console.log("Add photo response:", response);
      if (response.success === "true") {
        nav
          ? navigate("/edit-photos")
          : navigate(`/edit-photos/${response?.data[0]?.cart_item?.id}`);
      }
    } catch (err) {
      console.error("add photo after delete err:", err);
    } finally {
      setAddLoading(false);
    }
  };

  const [addLoading, setAddLoading] = useState(false);
  const addCroppedImg = async () => {
    if (croppedImageUrl) {
      try {
        setAddLoading(true);
        const response = await deletePhotoFromCart(id);
        console.log(response);
        if (response.success === "true") {
          await addPhoto(croppedImageUrl, true);
        } else {
          console.error("Failed to delete the old photo");
        }
      } catch (err) {
        console.error("add photo/s to cart error", err);
      }
    } else {
      navigate("/edit-photos");
    }
  };

  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const handleCroppedImageUrl = (url) => {
    setCroppedImageUrl(url);
    // console.log("Cropped image URL:", url);
  };

  useEffect(() => {
    getPhotoData();
  }, [updatePhotoArray]);

  useEffect(() => {
    logNavigation(`/edit-photos/${id}`);
    fetchMaterialsData();
    fetchEffectsData();
  }, []);
  useEffect(() => {
    fetchSizesData();
    fetchMatsData();
  }, [currentMaterial]);

  const { cropOpen, makeDecisionOpen, setMakeDecisionOpen, setCropOpen } =
    useContext(PopupContext);

  const fileInputRef = useRef(null);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    //console.log("file is : ", file);

    try {
      setLoading(true);
      const response = await deletePhotoFromCart(id);
      console.log(response);
      if (response.success === "true") {
        await addPhoto(file);
      }
    } catch (err) {
      console.error("replace low resolution photo err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //console.log("cropped img is : ", croppedImageUrl);
  }, [croppedImageUrl]);

  return loading ? (
    <Loading />
  ) : (
    <div
      className={`${
        theme === "dark" ? "bg-DarkMainBg" : "bg-[#f2e9e9]"
      } flex justify-center items-center w-full h-screen animate-flashing`}
    >
      {photo &&
        photo?.image_size_in_mb < 0.2 &&
        !(
          size === "size15x20" ||
          size === "size20x15" ||
          size === "size23x31" ||
          size === "size31x23" ||
          size === "size31x41" ||
          size === "size41x31" ||
          size === "size36x36"
        ) && (
          <div
            className={`${
              theme === "dark" ? "bg-DarkBoxBg" : "bg-[#fbf9f9]"
            } fixed top-0 left-0 w-full text-[14px] f-semi-bold py-1 border-b border-[#ebe7e7] flex justify-center items-center gap-1`}
          >
            <FaExclamationCircle /> <div>{t("Low Resolution Photo")}</div>{" "}
            <div
              onClick={() => fileInputRef.current.click()}
              className="underline c-pointer relative"
            >
              {t("Replace")}
              <input
                ref={fileInputRef}
                className="absolute w-full h-full top-0 left-0 invisible"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
        )}
      <div
        className={`${
          photo?.material?.name === "Canvas"
            ? "bg-transparent rounded-tr-[25px] rounded-bl-[25px]"
            : ""
        } ${
          theme === "dark"
            ? "shadow-framePhotoBoxShadowDM"
            : "shadow-framePhotoBoxShadow"
        } image_frame max-w-[500px] d-300`}
      >
        <div
          className={`${
            photo?.material?.name === "Black Frame"
              ? "border-black border-[7px]"
              : ""
          } ${
            photo?.material?.name === "White Frame"
              ? "border-white border-[7px]"
              : ""
          } ${
            photo?.material?.name === "Espresso Frame"
              ? "border-[#612718] border-[7px]"
              : ""
          } ${
            photo?.material?.name === "Canvas"
              ? "border-0 bg-transparent shadow-framePhotoBoxShadow rounded-tr-[25px] rounded-bl-[25px]"
              : ""
          } ${
            size === "size61x61"
              ? "w-[320px] h-[320px] max-vsm:w-[250px] max-vsm:h-[250px]"
              : ""
          } ${
            size === "size36x36"
              ? "w-[320px] h-[320px] max-vsm:w-[200px] max-vsm:h-[200px]"
              : ""
          } ${
            size === "size31x23"
              ? "w-[390px] h-[270px] max-vsm:w-[310px] max-vsm:h-[220px]"
              : ""
          } ${
            size === "size20x15"
              ? "w-[390px] h-[270px] max-vsm:w-[310px] max-vsm:h-[220px]"
              : ""
          } ${
            size === "size41x31"
              ? "w-[390px] h-[270px] max-vsm:w-[310px] max-vsm:h-[220px]"
              : ""
          } ${
            size === "size61x46"
              ? "w-[390px] h-[270px] max-vsm:w-[310px] max-vsm:h-[220px]"
              : ""
          } ${
            size === "size46x61"
              ? "w-[270px] h-[390px] max-vsm:w-[220px] max-vsm:h-[310px]"
              : ""
          } ${
            size === "size76x51"
              ? "w-[400px] h-[260px] max-vsm:w-[310px] max-vsm:h-[210px]"
              : ""
          } ${
            size === "size91x61"
              ? "w-[400px] h-[260px] max-vsm:w-[300px] max-vsm:h-[200px]"
              : ""
          } ${
            size === "size101x71"
              ? "w-[400px] h-[260px] max-vsm:w-[310px] max-vsm:h-[200px]"
              : ""
          } ${
            size === "size51x76"
              ? "w-[260px] h-[400px] max-vsm:w-[210px] max-vsm:h-[310px]"
              : ""
          } ${
            size === "size61x91"
              ? "w-[260px] h-[400px] max-vsm:w-[200px] max-vsm:h-[300px]"
              : ""
          } ${
            size === "size71x101"
              ? "w-[260px] h-[430px] max-vsm:w-[200px] max-vsm:h-[310px]"
              : ""
          } ${
            size === "size31x41"
              ? "w-[270px] h-[390px] max-vsm:w-[220px] max-vsm:h-[310px]"
              : ""
          } ${
            size === "size23x31"
              ? "w-[270px] h-[390px] max-vsm:w-[220px] max-vsm:h-[310px]"
              : ""
          } ${
            size === "size15x20"
              ? "w-[270px] h-[390px] max-vsm:w-[220px] max-vsm:h-[310px]"
              : ""
          } ${
            theme === "dark"
              ? "shadow-framePhotoBoxShadowDM"
              : "shadow-framePhotoBoxShadow"
          } relative`}
        >
          <div
            className={`${
              photo?.material?.name === "Black Frame" ? "bg-[#4d4d4d]" : ""
            } ${
              photo?.material?.name === "White Frame" ? "bg-[#ecebe9]" : ""
            } ${
              photo?.material?.name === "Espresso Frame" ? "bg-[#612718]" : ""
            }
             ${
               photo?.material?.name === "Canvas" ||
               photo?.material?.name === "Poster"
                 ? "hidden"
                 : ""
             } ${size === "size61x61" ? "h-[331.4px] max-vsm:h-[261px]" : ""} ${
              size === "size36x36" ? "h-[331.4px] max-vsm:h-[211px]" : ""
            } ${size === "size31x23" ? "h-[280px] max-vsm:h-[230px]" : ""} ${
              size === "size20x15" ? "h-[280px] max-vsm:h-[230px]" : ""
            } ${size === "size41x31" ? "h-[280px] max-vsm:h-[230px]" : ""} ${
              size === "size61x46" ? "h-[280px] max-vsm:h-[230px]" : ""
            } ${size === "size46x61" ? "h-[400px] max-vsm:h-[321px]" : ""} ${
              size === "size76x51" ? "h-[270px] max-vsm:h-[220px]" : ""
            } ${size === "size91x61" ? "h-[270px] max-vsm:h-[211px]" : ""} ${
              size === "size101x71" ? "h-[392px] max-vsm:h-[262px]" : ""
            } ${size === "size51x76" ? "h-[410px] max-vsm:h-[321px]" : ""} ${
              size === "size61x91" ? "h-[410px] max-vsm:h-[310px]" : ""
            } ${size === "size71x101" ? "h-[431.6px] max-vsm:h-[312px]" : ""} ${
              size === "size31x41" ? "h-[400px] max-vsm:h-[321px]" : ""
            } ${size === "size23x31" ? "h-[400px] max-vsm:h-[321px]" : ""} ${
              size === "size15x20" ? "h-[400px] max-vsm:h-[321px]" : ""
            } w-[8.84211px] -top-[6.3px] -right-[15.5px] absolute rightClipPath c-pointer`}
          ></div>
          <div
            className={`${
              photo?.material?.name === "Black Frame" ? "bg-[#333333]" : ""
            } ${
              photo?.material?.name === "White Frame" ? "bg-[#dfdedd]" : ""
            } ${
              photo?.material?.name === "Espresso Frame" ? "bg-[#5b2416]" : ""
            } ${
              photo?.material?.name === "Canvas" ||
              photo?.material?.name === "Poster"
                ? "hidden"
                : ""
            } ${
              size === "size61x61"
                ? "w-[329.5px] max-vsm:w-[259.6px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size36x36"
                ? "w-[329.5px] max-vsm:w-[206px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size31x23"
                ? "w-[400px] max-vsm:w-[318px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size20x15"
                ? "w-[400px] max-vsm:w-[318px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size41x31"
                ? "w-[400px] max-vsm:w-[318px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size61x46"
                ? "w-[400px] max-vsm:w-[318px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size46x61"
                ? "w-[280px] max-vsm:w-[226px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size76x51"
                ? "w-[410px] max-vsm:w-[318px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size91x61"
                ? "w-[410px] max-vsm:w-[307px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size51x76"
                ? "w-[270px] max-vsm:w-[218px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size61x91"
                ? "w-[270px] max-vsm:w-[208px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size31x41"
                ? "w-[280px] max-vsm:w-[226px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size23x31"
                ? "w-[280px] max-vsm:w-[226px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                : ""
            } ${
              size === "size15x20"
                ? "w-[280px] max-vsm:w-[226px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                : ""
            } h-[11.7895px] -bottom-[18.4px] -left-[6.8px] absolute c-pointer`}
          ></div>
          <div
            className={`${
              photo?.material?.name === "Black Frame" ? "bg-transparent" : ""
            } ${
              photo?.material?.name !== "Canvas" &&
              photo?.material?.name !== "Poster" &&
              photo?.mat?.name === "mat1"
                ? "p-[0px]"
                : ""
            } ${
              photo?.material?.name !== "Canvas" &&
              photo?.material?.name !== "Poster" &&
              photo?.mat?.name === "mat2"
                ? "p-[10px]"
                : ""
            } ${
              photo?.material?.name !== "Canvas" &&
              photo?.material?.name !== "Poster" &&
              photo?.mat?.name === "mat3"
                ? "p-[20px]"
                : ""
            } ${
              photo?.material?.name !== "Canvas" &&
              photo?.material?.name !== "Poster" &&
              photo?.mat?.name === "mat4"
                ? "p-[40px]"
                : ""
            } relative w-full h-full c-pointer`}
          >
            <img
              className={`${
                photo?.material?.name === "Canvas"
                  ? "rounded-tr-[25px] rounded-bl-[25px] shadow-2xl"
                  : ""
              } ${photo?.effect?.name === "silver" ? "silver" : ""} ${
                photo?.effect?.name === "noir" ? "noir" : ""
              } ${photo?.effect?.name === "vivid" ? "vivid" : ""} ${
                photo?.effect?.name === "dramatic" ? "dramatic" : ""
              } image_element`}
              src={
                croppedImageUrl
                  ? URL.createObjectURL(croppedImageUrl)
                  : photo?.url_photo
              }
              alt={`Preview ${photo?.id}`}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col fixed bottom-[40px] left-[50%] -translate-x-[50%] w-fit bg-transparent">
        <div
          className={`${
            theme === "dark" ? "text-DarkMainText/80" : "text-SecondText"
          } w-full flex justify-between items-center mb-[20px] font-[600] min-w-[240px] text-[20px]`}
        >
          <h1
            className={`${
              theme === "dark" ? "bg-DarkBoxBg" : "bg-MainBg"
            } flex gap-1 vsm:gap-1 text-[18px] flex-col r-12 px-2 py-1 editPhotoBoxShadow`}
          >
            {t("Edit Tile")}
            <div className="text-[13px] flex">
              <div>{`${photo?.size?.length_inch}x${photo?.size?.width_inch}"`}</div>
              <div>{`(${photo?.size?.length_cm}x${photo?.size?.width_cm}cm)`}</div>
            </div>
          </h1>
          <Link
            onClick={addCroppedImg}
            className={`${addLoading && "cursor-default pointer-events-none"} ${
              theme === "dark" ? "bg-DarkMainText" : "bg-[#639af938]"
            } flex justify-center items-center text-PrimaryColor hover:brightness-75 px-2 d-300 ml-[8px] min-w-[60px] h-[26px] rounded-[13px] text-[15px]`}
          >
            {addLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              t("Done")
            )}
          </Link>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "bg-DarkMainBg text-DarkMainText"
              : "bg-white border-[#f2e9e9]"
          } r-12 py-[12px] px-[8px] border editPhotoBoxShadow`}
        >
          <div className="flex items-center">
            <div
              onClick={() => handleOpenPopup("material")}
              className={`${
                theme === "dark"
                  ? "text-DarkMainText hover:bg-white/20"
                  : "text-MainText hover:bg-[#fbf6f6]"
              } flex flex-col w-[58px] gap-[4px] c-pointer hover:rounded-[5px] text-center d-300`}
            >
              <div className="w-[32px] h-[32px] mx-auto flex justify-center items-center">
                {materialIcon}
              </div>
              <h1 className="text-[13px] font-[600]">{t("Material")}</h1>
            </div>
            <div className="flex items-center justify-between">
              <div
                onClick={() => handleOpenPopup("size")}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } flex flex-col w-[58px] gap-[4px] c-pointer hover:rounded-[5px] text-center d-300`}
              >
                <div className="w-[32px] h-[32px] mx-auto flex justify-center items-center">
                  {sizeIcon}
                </div>
                <h1 className="text-[13px] font-[600]">{t("Size")}</h1>
              </div>
              <div
                onClick={() => handleOpenPopup("effect")}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } flex flex-col w-[58px] gap-[4px] c-pointer hover:rounded-[5px] text-center d-300`}
              >
                <div className="w-[32px] h-[32px] mx-auto flex justify-center items-center">
                  {effectIcon}
                </div>

                <h1 className="text-[13px] font-[600]">{t("Impact")}</h1>
              </div>
              {photo?.material?.name !== "Canvas" &&
                photo?.material?.name !== "Poster" && (
                  <div
                    onClick={() => handleOpenPopup("mat")}
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText hover:bg-white/20"
                        : "text-MainText hover:bg-[#fbf6f6]"
                    } flex flex-col w-[58px] gap-[4px] c-pointer hover:rounded-[5px] text-center d-300`}
                  >
                    <div className="w-[32px] h-[32px] mx-auto flex justify-center items-center">
                      {matIcon}
                    </div>
                    <h1 className="text-[13px] font-[600]">{t("Mat")}</h1>
                  </div>
                )}
              <div
                onClick={() => setCropOpen(true)}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } flex flex-col w-[58px] gap-[4px] c-pointer hover:rounded-[5px] text-center d-300`}
              >
                <div className="w-[32px] h-[32px] mx-auto flex justify-center items-center">
                  {cropIcon}
                </div>
                <h1 className="text-[13px] font-[600]">{t("Crop")}</h1>
              </div>
              <div
                onClick={() => {
                  setMakeDecisionOpen(true);
                }}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } flex flex-col w-[58px] gap-[4px] c-pointer hover:rounded-[5px] text-center d-300`}
              >
                <div className="w-[32px] h-[32px] mx-auto flex justify-center items-center">
                  {deleteIcon}
                </div>
                <h1 className="text-[13px] font-[600]">{t("Delete")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        toggleSwitch={makeDecisionOpen}
        children={
          <MakeDecision
            action={t("delete")}
            type={t("photo item")}
            itemId={id}
          />
        }
        overLayClassName="z20"
        className={`z30 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] d-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClose={() => setMakeDecisionOpen(false)}
      />
      <Popup
        toggleSwitch={activePopup === "material"}
        children={<MaterialList handleOpenPopup={handleOpenPopup} />}
        overLayClassName="bg-transparent z20"
        className={`${
          activePopup === "material" ? "bottom-[14px]" : "bottom-full"
        } fixed z30 r-12 photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-w-[530px] max-sm:w-[90%] d-300`}
        onClose={() => setActivePopup(null)}
      />
      <Popup
        toggleSwitch={activePopup === "size"}
        children={<SizeList handleOpenPopup={handleOpenPopup} />}
        overLayClassName="bg-transparent z20"
        className={`${
          activePopup === "size" ? "bottom-[14px]" : "bottom-full"
        } fixed z30 r-12 photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-vsm:w-[90%] d-300`}
        onClose={() => setActivePopup(null)}
      />
      <Popup
        toggleSwitch={activePopup === "effect"}
        children={<EffectList handleOpenPopup={handleOpenPopup} />}
        overLayClassName="bg-transparent z20"
        className={`${
          activePopup === "effect" ? "bottom-[14px]" : "bottom-full"
        } fixed z30 r-12 photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-sm:w-[90%] d-300`}
        onClose={() => setActivePopup(null)}
      />
      <Popup
        toggleSwitch={activePopup === "mat"}
        children={<MatList handleOpenPopup={handleOpenPopup} />}
        overLayClassName="bg-transparent z20"
        className={`${
          activePopup === "mat" ? "bottom-[14px]" : "bottom-full"
        } fixed z30 r-12 photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-sm:w-[90%] d-300`}
        onClose={() => setActivePopup(null)}
      />
      <Popup
        toggleSwitch={cropOpen}
        children={
          <CropPhoto photo={photo} onCroppedImageUrl={handleCroppedImageUrl} />
        }
        overLayClassName="bg-transparent -z20"
        className={`${
          cropOpen ? "bottom-[14px]" : "bottom-full"
        } w-full h-full fixed z10 r-12 photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] d-300`}
        onClose={() => setCropOpen(null)}
      />
    </div>
  );
}

export default EditOnePhoto;
