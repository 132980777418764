import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import FAQsList from "./FAQsList";
import { PopupContext } from "../../Context/PopupContext";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import Popup from "../../Shared/Components/Popup";

function FAQs() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { FAQsOpen, setFAQsOpen } = useContext(PopupContext);

  return (
    <Popup
      toggleSwitch={FAQsOpen}
      children={
        <div
          className={`${
            theme === "dark" ? "bg-DarkMainBg text-DarkMainText" : "bg-MainBg"
          } rounded-[20px] max-md:rounded-none md:languageBoxShadow flex flex-col max-md:h-screen`}
        >
          <div className="flex px-[12px] justify-between items-center mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-[18px] leading-[14px] h-[56px]">
            <div
              className={`${
                theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
              } p-[5px] rounded-full`}
            >
              <AiOutlineClose
                className="c-pointer"
                onClick={() => {
                  setFAQsOpen(false);
                }}
                size={26}
              />
            </div>
            <h1
              className={`${
                theme === "dark" ? "text-DarkMainText" : "text-MainText"
              } md:py-[27px]`}
            >
              {t("FAQs")}
            </h1>
            <div className="invisible"></div>
          </div>
          <FAQsList />
        </div>
      }
      overLayClassName="z40"
      className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
      onClose={() => setFAQsOpen(false)}
    />
  );
}

export default FAQs;
