import React, { useEffect, useState } from "react";
import ReviewSkeleton from "./Skeleton/ReviewSkeleton";
import { FaRegStar, FaStar } from "react-icons/fa6";
import { fetchReviewsForPhoto } from "../../Api/Artphotos";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { Link } from "react-router-dom";
import BreakDiv from "../../Shared/Components/BreakDiv";
import CustomPagination from "../../Shared/Components/CustomPagination";

function ReviewsList() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const renderStars = (ratingValue) => {
    const stars = [];
    // Generate filled stars based on ratingValue
    for (let i = 0; i < ratingValue; i++) {
      stars.push(<FaStar key={i} color="gold" />);
    }
    // Generate empty stars for the remaining rating slots
    for (let j = ratingValue; j < 5; j++) {
      stars.push(<FaRegStar key={j} color="gold" />);
    }
    return stars;
  };
  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [totalReviews, setTotalReviews] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setCurrentPage(selectedPage);
  };
  const fetchReviews = async () => {
    try {
      setReviewsLoading(true);
      const response = await fetchReviewsForPhoto(currentPage);
      // console.log(response);
      setTotalReviews(response.total_count);
      setReviews(response.reviews.data);
      setCurrentPage(response.reviews.current_page);
      setTotalPages(response.reviews.last_page);
      setItemPerPage(response.reviews.per_page);
    } catch (err) {
      console.error("fetch photo reviews err", err);
    } finally {
      setReviewsLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [currentPage]);
  return reviewsLoading ? (
    <ReviewSkeleton />
  ) : (
    reviews.length > 0 && (
      <div className="flex flex-col gap-3 mb-10">
        {
          reviews.length > 1 ? (
            <h1 className="flex items-center gap-2 f-semi-bold text-[20px] max-md:text-[18px] max-vsm:text-[16px]">
              {totalReviews} {t("Reviews")}
              <div className="flex items-center gap-1">{renderStars(5)}</div>
            </h1>
          ) : null
          // <h1 className="flex items-center gap-2 text-[28px] max-md:text-[24px] max-vsm:text-[20px]">
          //   {t("No feedback available")}
          // </h1>
        }
        <div className="flex flex-col gap-3">
          {reviews &&
            reviews.map((review, index) => {
              return (
                <div key={index} className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <div className="rounded-full w-[50px] h-[50px] overflow-hidden">
                      <img
                        className="w-full h-full o-cover"
                        src={review.user.url_image}
                        alt=""
                      />
                    </div>
                    <div
                      className={`${
                        theme === "dark" ? "text-DarkMainText" : "text-MainText"
                      } f-semi-bold text-[20px] max-md:text-[18px] max-vsm:text-[16px]`}
                    >
                      {review?.user?.name}
                    </div>
                  </div>
                  <div className="max-w-[250px] max-h-[200px] rounded-[6px] overflow-hidden">
                    {review?.url_image ? (
                      <img
                        className="w-full h-full o-cover"
                        src={review.url_image}
                        alt=""
                        onError={(e) => (e.target.style.display = "none")} // Hide the image element on error
                      />
                    ) : null}
                  </div>

                  <div
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText/70"
                        : "text-SecondText"
                    } text-[18px] max-md:text-[16px]`}
                  >
                    {review?.note}
                  </div>
                  <h1 className={`flex items-center`}>
                    {renderStars(review?.rate_value)}
                  </h1>
                  <div className="flex gap-2">
                    <Link
                      to={`/art-photos/${review.picture.id}`}
                      className="max-w-[120px] max-h-[140px] rounded-[6px] overflow-hidden"
                    >
                      <img
                        className="w-full h-full o-cover"
                        src={review?.picture?.url_picture[0]?.url_image}
                        alt=""
                      />
                    </Link>
                    <Link
                      to={`/art-photos/${review.picture.id}`}
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText/70"
                          : "text-SecondText"
                      } f-semi-bold hover:underline d-300 text-[18px] max-md:text-[16px]`}
                    >
                      {review?.picture?.name}
                    </Link>
                  </div>
                  <BreakDiv />
                </div>
              );
            })}
        </div>
        <CustomPagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
        />
      </div>
    )
  );
}

export default ReviewsList;
