import React from "react";

function OfferTimerItem({ value, text }) {
  return (
    <div className="flex flex-col min-w-[62px] max-vsm:min-w-[49px] items-center p-2 rounded-[20px] bg-PrimaryColor t-white">
      <span className="font-[700] border-b-[2px] border-b-[#777] text-[20px] max-vsm:text-[16px]">
        {value}
      </span>
      <span className="uppercase text-[14px] max-vsm:text-[10px]">{text}</span>
    </div>
  );
}

export default OfferTimerItem;
