import { toastError } from "../Shared/Components/ToastNotification/Toast";
import { apiClient } from "./api";

export const fetchMaterials = async () => {
  try {
    const response = await apiClient.get(`/api/Material`);
    return response.data;
  } catch (error) {
    console.error("fetch materials err", error);
  }
};

export const fetchSizes = async (id) => {
  try {
    const response = await apiClient.get(`/api/SizeByMaterial?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("fetch sizes err", error);
  }
};

export const fetchEffects = async (id) => {
  try {
    const response = await apiClient.get(`/api/Effect`);
    return response.data;
  } catch (error) {
    console.error("fetch effects err", error);
  }
};

export const fetchMats = async (id) => {
  try {
    const response = await apiClient.get(`/api/Mat?material_id=${id}`);
    return response.data;
  } catch (error) {
    console.error("fetch mats err", error);
  }
};

export const fetchEditPhotos = async () => {
  try {
    const response = await apiClient.get(`/api/cartItem`);
    return response.data;
  } catch (error) {
    console.error("fetch edit photos err", error);
  }
};

export const fetchEditPhotosByPhotoId = async (id) => {
  try {
    const response = await apiClient.get(`/api/cartItemById?id=${id}`);
    return response.data;
  } catch (error) {
    console.error(`fetch edit photos by photo id ${id} err : `, error);
    if (error.response.data.message === "Not Found") {
      window.location.pathname = "/edit-photos"
    }
  }
};

export const addPhotoToCart = async (photosArray) => {
  try {
    const formData = new FormData();

    // Append photo data to formData
    photosArray.forEach((item, index) => {
      formData.append(`array_ids[${index}][material_id]`, item.material_id);
      formData.append(`array_ids[${index}][size_id]`, item.size_id);
      formData.append(`array_ids[${index}][url_photo]`, item.url_photo);
      formData.append(`array_ids[${index}][effect_id]`, item.effect_id);
      formData.append(`array_ids[${index}][mat_id]`, item.mat_id);
    });

    const response = await apiClient.post(`/api/addPhotoToCart`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("add photo/s to cart err", error);
    // toastError(error.response.data.errors[0]);
  }
};

export const updatePhotoInCart = async (endPoint, data, id) => {
  try {
    const response = await apiClient.post(`/api/${endPoint}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("update photo in cart err", error);
  }
};

export const deletePhotoFromCart = async (id) => {
  try {
    const response = await apiClient.delete(`/api/DeleteCartItem?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("delete photo from cart err", error);
  }
};

export const deletePhotoFromCartMock = async (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      // console.log(`Mock delete for photo ${id}`);
      resolve({ success: true }); // Resolve with a mock success response
    }, 1000); // Mock delay of 1 second to simulate an async operation
  });
};

export const showTotalCartPrice = async () => {
  try {
    const response = await apiClient.get(`/api/TotalPriceInCart`);
    return response.data;
  } catch (error) {
    console.error("show total price in cart err", error);
  }
};

export const showCartInfo = async () => {
  try {
    const response = await apiClient.get(`/api/ShowCartItemInCart`);
    return response.data;
  } catch (error) {
    console.error("show cart information err", error);
  }
};

export const fetchCountries = async () => {
  try {
    const response = await apiClient.get(`/api/Country`);
    return response.data;
  } catch (error) {
    console.error("fetch countries err", error);
  }
};

export const fetchRegionsByCountry = async (id) => {
  try {
    const response = await apiClient.get(
      `/api/RegionByCountry?country_id=${id}`
    );
    return response.data;
  } catch (error) {
    console.error("fetch regions err", error);
  }
};

export const postAddress = async (data) => {
  try {
    const response = await apiClient.post(`/api/address`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("post address information err", error);
    toastError(error.response.data.errors[0])
  }
};

export const fetchAddress = async (id) => {
  try {
    const response = await apiClient.get(`/api/address?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("post address information err", error);
  }
};

export const updateAddress = async (id, data) => {
  try {
    const response = await apiClient.post(
      `/api/address/update?id=${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("post address information err", error);
  }
};

export const checkOutOrder = async (data) => {
  try {
    const response = await apiClient.post(`/api/checkOutOrder`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("check out order err", error);
    toastError(error.response.data.errors[0])
  }
};

export const postDiscountCode = async (data) => {
  try {
    const response = await apiClient.post(`/api/verfiyCode`, { code: data });
    return response.data;
  } catch (error) {
    console.error("post err", error);
    console.error("post discount code err", error.response.data.errors[0]);
    toastError(error.response.data.errors[0]);
  }
};
