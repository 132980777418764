import React, { useEffect, useState } from "react";
import { fetchUserById } from "../../Api/Dashboard";
import { useParams } from "react-router-dom";
import CustomPagination from "../../Shared/Components/CustomPagination";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";
import DashboardOutlet from "../Components/DashboardOutlet";

function UsersById() {
  const { theme } = useTheme();
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [total, setTotal] = useState("");
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetchUserById(id, pageNumber);
      console.log(response.data);
      setData(response.data);
      setTotalPages(data.last_page);
      setItemPerPage(data.per_page);
      setPageNumber(data.current_page);
      setFrom(data.from);
      setTo(data.to);
      setTotal(data.total);
    } catch (err) {
      console.error("get user by id data err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [pageNumber]);
  return (
    <DashboardOutlet title={`User Details`}>
      <div>
        <table
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
          } w-full table-auto`}
        >
          <thead>
            <tr>
              <th className="border px-4 py-2">Id</th>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Photo</th>
              <th className="border px-4 py-2">Items</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 20 }).map((_, outInex) => (
                <tr key={outInex}>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <td key={index}>
                      <Skeleton width={"100%"} height={35} />
                    </td>
                  ))}
                </tr>
              ))
            ) : data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index} className="">
                  <td className="border text-center px-4 py-2">{item?.id}</td>
                  <td className="border text-center px-4 py-2">{item?.name}</td>
                  <td className="border text-center px-4 py-2">
                    {item?.email}
                  </td>
                  <td className="border text-center px-4 py-2">
                    <div className="w-[50px] h-[50px] rounded-full overflow-hidden m-auto">
                      <img
                        className="w-full h-full o-cover"
                        src={item?.url_image}
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="border text-center px-4 py-2">
                    <div className="flex flex-col gap-2">
                      {item?.cart?.cart_items?.map((item) => (
                        <div className="w-[200px] h-[200px] overflow-hidden m-auto">
                          <img
                            className="w-full h-full o-cover"
                            src={item?.url_photo}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div>No Data</div>
            )}
          </tbody>
        </table>
      </div>
      <CustomPagination
        totalPages={totalPages}
        currentPage={pageNumber}
        itemPerPage={itemPerPage}
        handlePageClick={handlePageClick}
        from={from}
        to={to}
        total={total}
        dashMode={true}
      />
    </DashboardOutlet>
  );
}

export default UsersById;
