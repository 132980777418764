import React, { useContext, useEffect, useState } from "react";
import CheckoutForm from "../Components/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PhotoContext } from "../../Context/PhotoContext";
import Loading from "../../Shared/Components/Loading";
import { paymentIntent } from "../../Api/Paymentdetails";
import { useParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentForm() {
  const [clientSecret, setClientSecret] = useState("");

  const { orderNumber } = useParams();
  const { loading, setLoading } = useContext(PhotoContext);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const paymentCLSC = async () => {
    setLoading(true);
    try {
      const response = await paymentIntent(orderNumber);
      // console.log("Client Secret:", response.client_secret);

      // console.log(response);
      setClientSecret(response.client_secret);
      localStorage.setItem("paymentId", response.paymentIntent_Id);
    } catch (error) {
      console.error("Failed to fetch intent:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    paymentCLSC();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    clientSecret && (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    )
  );
}

export default PaymentForm;
