import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../Components/DashboardSidebar";
import { useTheme } from "../../Context/ThemeContext";

function Dashboard() {
  const { theme } = useTheme();

  return (
    <div
      style={{ direction: "ltr" }}
      className={`${
        theme === "dark" ? "bg-DarkMainBg" : "bg-MainBg"
      } flex h-screen p-2 overflow-x-hidden`}
    >
      <DashboardSidebar />
      <div className="px-2 w-full max-w-full">
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
