import React, { useContext } from "react";
import MatBoxItem from "./MatBoxItem";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { updatePhotoInCart } from "../../Api/Editphotos";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function MatList({ handleOpenPopup }) {
  const { id } = useParams();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    setUpdatePhotoArray,
    selectedMat,
    setSelectedMat,
    mats,
    matsLoading,
  } = useContext(PhotoContext);

  const handleMatClick = async (matId) => {
    setSelectedMat(matId);
    const requestBody = { mat_id: matId };

    try {
      const response = await updatePhotoInCart(
        id ? `updateCartItem?id=${id}` : "updateCartItem",
        requestBody,
        id
      );
      // console.log("Success:", response.data);
      setUpdatePhotoArray((prev) => prev + 1);
    } catch (error) {
      console.error("Update photo in cart error:", error);
    }
  };

  return (
    <div
      className={`${
        theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "text-MainText bg-MainBg"
      } max-w-[630px] r-12 w-full min-w-[260px] vsm:min-w-[388px] overflow-y-auto flex flex-col max-h-full`}
    >
      <div className="flex justify-between items-center m-[20px]">
        <h1
          className={`${
            theme === "dark" ? "text-DarkMainText/70" : "text-SecondText"
          } font-[600] text-[16px] leading-[16px]`}
        >
          {t("Choose Mat")}
        </h1>
        <div
          onClick={() => handleOpenPopup("mat")}
          className="text-PrimaryColor c-pointer p-[1em] -m-[1em]"
        >
          {t("Completed")}
        </div>
      </div>
      <div className="flex px-[20px] pb-[24px] gap-[12px] overflow-hidden w-full sizeSwiper">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={20}
          slidesPerView={"auto"}
          navigation
        >
          {matsLoading
            ? Array.from({ length: 4 }).map((_, index) => (
                <SwiperSlide
                  key={index}
                  className="min-w-[130px] max-w-[130px] min-h-[130px]"
                >
                  <div className="w-full h-full">
                    <Skeleton width={`100%`} className="h-full" />
                  </div>
                </SwiperSlide>
              ))
            : mats.map((mat) => (
                <SwiperSlide
                  key={mat.id}
                  className="min-h-[130px] max-h-[130px] max-w-[130px]"
                >
                  <MatBoxItem
                    key={mat.id}
                    onClick={() => handleMatClick(mat.id)}
                    isActive={selectedMat === mat.id}
                    image={mat.url_image}
                  />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </div>
  );
}

export default MatList;
