import React, { useContext, useEffect } from "react";
import LandingArt from "./Sections/LandingArt";
import Container from "../../../Shared/Components/Container";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useTheme } from "../../../Context/ThemeContext";
import ArtPhotosHome from "./Sections/ArtPhotosHome";
import { PhotoContext } from "../../../Context/PhotoContext";
import { useParams } from "react-router-dom";

function ArtHomeByCategoryId() {
  const { id } = useParams();
  const { theme } = useTheme();
  const { logNavigation } = useContext(PhotoContext);
  useEffect(() => {
    logNavigation(`/art-home/${id}`);
  }, []);

  return (
    <div
      className={`${theme === "dark" ? "bg-DarkMainBg" : "bg-white"} w-full`}
    >
      <Navbar
        sticky={true}
        className={`${
          theme === "dark" ? "bg-DarkMainBg" : "bg-white"
        } z20 mb-0`}
      />
      <LandingArt />
      <Container className={`flex flex-col gap-5`}>
        <ArtPhotosHome categoryId={id} />
        <Footer />
      </Container>
    </div>
  );
}

export default ArtHomeByCategoryId;
