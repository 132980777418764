import React, { useContext } from "react";
import { PopupContext } from "../../Context/PopupContext";
import { MdLanguage } from "react-icons/md";
import MainButton from "../../Shared/Components/MainButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import Popup from "../../Shared/Components/Popup";

function PrivacySettings() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { privacySettingsOpen, setPrivacySettingsOpen, setLanguageOpen } =
    useContext(PopupContext);
  const setPrivacyShowed = () => {
    setPrivacySettingsOpen(false);
    localStorage.setItem("privacyShowed", true);
  };
  return (
    <Popup
      toggleSwitch={privacySettingsOpen}
      children={
        <div
          className={`${
            theme === "dark" ? "bg-DarkMainBg" : "bg-white"
          } rounded-[20px] max-md:rounded-none md:languageBoxShadow md:w-[625px] flex flex-col max-md:h-fit`}
        >
          <div
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } pt-[24px] px-[24px] pb-[12px]`}
          >
            <div
              className={`flex justify-between items-center mb-[8px] font-[700] text-[18px] leading-[14px]`}
            >
              <h1 className={`text-[19px] font-[700] leading-[18px]`}>
                {t("Information Protection Settings")}
              </h1>
              <MdLanguage
                onClick={() => setLanguageOpen(true)}
                className="c-pointer"
                size={25}
              />
            </div>
            <div className={`py-[4px] leading-[1.25] bm-[8px]`}>
              {t(
                "Our website utilizes external website tracking tools to enrich and customize our services, while also serving advertising goals. The activation of optional cookies relies on your consent, which you are free to revoke at any time"
              )}
            </div>
          </div>
          <div className="px-[24px] flex md:justify-center items-center gap-3">
            <button
              onClick={() => {
                setPrivacyShowed();
              }}
              className={`${
                theme === "dark"
                  ? "bg-MainBg hover:bg-[#eae0e4]"
                  : "bg-[#e8e8e8] hover:bg-[#d0d0d0]"
              } md:min-w-[280px] max-w-[280px] max-md:min-w-[125px] max-md:max-w-[200px] f-bold text-PrimaryColor d-300 c-pointer rounded-[4px] py-[12px] px-[16px] mb-[24px] flex justify-center items-center`}
            >
              {t("Refuse")}
            </button>
            <MainButton
              content={`${t("Agree")}`}
              className="md:min-w-[280px] max-w-[280px] max-md:min-w-[125px] max-md:max-w-[200px] rounded-[4px] py-[12px] px-[16px] mb-[24px]"
              onClick={() => setPrivacyShowed()}
            />
          </div>
        </div>
      }
      overLayClassName="z10"
      className={`z20 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-fit max-vsm:overflow-y-auto rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
    />
  );
}

export default PrivacySettings;
