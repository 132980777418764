import React, { useState, useEffect } from "react";
import OfferTimerItem from "./OfferTimerItem";
import Container from "../../Shared/Components/Container";
import { useTranslation } from "react-i18next";

function OfferTimer() {
  const { t } = useTranslation();
  const calculateTimeLeft = () => {
    const offerEndTime = new Date("2024-12-03T23:59:59"); // Set your offer end date and time here
    const now = new Date();
    const difference = offerEndTime - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    timeLeft.days !== undefined && (
      <div className="bg-black py-4 ">
        <Container className="mx-auto flex flex-wrap justify-between items-center max-md:flex-col max-md:gap-2">
          <div className="flex-1 px-2">
            <div className="max-w-[370px] max-h-[150px] max-md:hidden">
              <img
                className="w-full h-full o-cover"
                src="https://backend.tecrek.com/public/Photos/offer3.png"
                alt=""
              />
            </div>
          </div>
          <div className="max-w-[300px] max-h-[150px] md:hidden">
            <img
              className="w-full h-full o-cover"
              src="https://backend.tecrek.com/public/Photos/offer2.png"
              alt=""
            />
          </div>
          <div className="flex-1 pl-5 ml-5">
            <div className="max-w-[340px] max-h-[150px] max-md:hidden">
              <img
                className="w-full h-full o-cover"
                src="https://backend.tecrek.com/public/Photos/offer2.png"
                alt=""
              />
            </div>
          </div>
          <div className="max-w-[230px] max-h-[150px] md:hidden mb-3">
            <img
              className="w-full h-full o-cover "
              src="https://backend.tecrek.com/public/Photos/offer3.png"
              alt=""
            />
          </div>

          <div className="flex-1 flex flex-col  items-center text-center gap-2">
            <h1 className="uppercase text-DarkMainText font-[700]">
              {t("Hurry! Offer Ends Soon!")}
            </h1>
            <div className="flex items-center gap-2 ">
              <OfferTimerItem value={timeLeft.days} text={`days`} />
              <OfferTimerItem value={timeLeft.hours} text={`hours`} />
              <OfferTimerItem value={timeLeft.minutes} text={`mins`} />
              <OfferTimerItem value={timeLeft.seconds} text={`secs`} />
            </div>
            <h1 className="uppercase text-DarkMainText font-[700]">
              {t("Don’t Miss Out!")}
            </h1>
          </div>
        </Container>
      </div>
    )
  );
}

export default OfferTimer;
