import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function SizeBoxItem({
  isActive,
  onClick,
  widthCm,
  lengthCm,
  widthInch,
  lengthInch,
}) {
  const { theme } = useTheme();
  return (
    <div
      onClick={onClick}
      className={`${
        isActive
          ? "border-PrimaryColor text-PrimaryColor cursor-default pointer-events-none"
          : "border-[#ebe7e7] c-pointer"
      } d-300 border-[2px] shrink-0 w-full h-[108px] flex flex-col items-center rounded-[5px]`}
    >
      <div
        className={`${
          theme === "dark" && "text-DarkMainText/70"
        } w-full h-full grow flex justify-center items-center`}
      >
        <div
          className={`${widthCm === lengthCm && "w-[30px] h-[30px]"} ${
            widthCm < lengthCm && "w-[35px] h-[25px]"
          } ${widthCm > lengthCm && "w-[25px] h-[35px]"}  ${
            isActive ? "border-PrimaryColor" : "border-[#958289]"
          }  border-[2px] d-300`}
        ></div>
      </div>
      <div
        className={`${
          isActive
            ? "text-PrimaryColor"
            : theme === "dark"
            ? "text-DarkMainText/70"
            : "text-MainText"
        } flex flex-col gap-1 text-center font-[600] text-[14px] leading-[14px] mb-[8px] px-1`}
      >
        <div>{`${lengthInch}x${widthInch}" `}</div>
        <div>{`${lengthCm}x${widthCm} cm`}</div>
      </div>
    </div>
  );
}

export default SizeBoxItem;
