import React from "react";
import { useTheme } from "../../Context/ThemeContext";
import { FaMinus, FaPlus, FaRegStar, FaStar } from "react-icons/fa6";

function ReviewItem({ image, description, pic, name }) {
  const { theme } = useTheme();
  const renderStars = (ratingValue) => {
    const stars = [];
    // Generate filled stars based on ratingValue
    for (let i = 0; i < ratingValue; i++) {
      stars.push(<FaStar key={i} color="gold" />);
    }
    // Generate empty stars for the remaining rating slots
    for (let j = ratingValue; j < 5; j++) {
      stars.push(<FaRegStar key={j} color="gold" />);
    }
    return stars;
  };
  return (
    <div
      className={`${
        theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
      } r-12 min-h-full h-[550px]`}
    >
      <div className="md:h-[300px] max-md:max-h-[350px] overflow-hidden rounded-t-[12px]">
        <img className="w-full h-full o-cover" src={image} alt="" />
      </div>

      <div className="py-[15px] px-[18px] text-[15px] flex flex-col justify-between min-h-[250px] max-md:min-h-[200px]">
        <h1
          className={`${
            theme === "dark" ? "text-DarkMainText" : "text-SecondText"
          } mb-6`}
        >
          {description}
        </h1>

        <div className="flex items-center gap-2">
          {/* Profile Image */}
          <div className="rounded-full w-[50px] h-[50px] overflow-hidden">
            <img className="w-full h-full o-cover" src={pic} alt="" />
          </div>

          {/* Name and Stars */}
          <div className="flex flex-col">
            {/* Name */}
            <h2
              className={`${
                theme === "dark" ? "text-DarkMainText/70" : "text-[#777]"
              }`}
            >
              {name}
            </h2>

            {/* Review Stars */}
            <div className="flex items-center gap-1">{renderStars(5)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewItem;
