import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaAd, FaPowerOff, FaUsers } from "react-icons/fa";
import { MdPhotoLibrary, MdOutlineArrowForwardIos } from "react-icons/md";
import { HiDocumentReport, HiKey } from "react-icons/hi";
import { RxSize } from "react-icons/rx";
import { FaCartShopping, FaMapLocation } from "react-icons/fa6";
import { PopupContext } from "../../Context/PopupContext";
import { BsPersonVcardFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { PhotoContext } from "../../Context/PhotoContext";

function DashboardSidebar() {
  const { dashboardSideBarOpen, setDashboardSideBarOpen } =
    useContext(PopupContext);
  const { handleLogout } = useContext(PhotoContext);
  const { id } = useParams();
  const location = useLocation();
  function isActive(path) {
    return location.pathname === path;
  }
  return (
    <nav
      className={`sticky top-0 left-0 h-screen bg-PrimaryColor t-white overflow-y-auto ${
        dashboardSideBarOpen ? "w-64" : "w-[54px]"
      } transition-width d-300`}
    >
      <h1
        className={`${
          dashboardSideBarOpen ? "block" : "invisible py-5"
        } px-2 w-full pt-2 text-center t-white f-bold text-[30px]`}
      >
        Dashboard
      </h1>
      <Link
        to="/dashboard"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${
                      isActive("/dashboard") ||
                      isActive(`/dashboard/user/${id}`)
                        ? "bg-[#6d5d45]"
                        : ""
                    }`}
        exact={true}
      >
        <FaUsers size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Users
        </h1>
      </Link>
      <Link
        to="art"
        className={` flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${isActive("/dashboard/art") ? "bg-[#6d5d45]" : ""}`}
      >
        <MdPhotoLibrary size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Art Photos
        </h1>
      </Link>
      <Link
        to="sizes"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${isActive("/dashboard/sizes") ? "bg-[#6d5d45]" : ""}`}
      >
        <RxSize size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Sizes
        </h1>
      </Link>
      <Link
        to="orders"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${
                      isActive("/dashboard/orders") ||
                      isActive(`/dashboard/orders/${id}`)
                        ? "bg-[#6d5d45]"
                        : ""
                    }`}
      >
        <FaCartShopping size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Orders
        </h1>
      </Link>
      <Link
        to="reports"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${isActive("/dashboard/reports") ? "bg-[#6d5d45]" : ""}`}
      >
        <HiDocumentReport size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Reports
        </h1>
      </Link>
      <Link
        to="vividVisions-visitors"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${
                      isActive("/dashboard/vividVisions-visitors")
                        ? "bg-[#6d5d45]"
                        : ""
                    }`}
      >
        <BsPersonVcardFill size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          VividVisions Visitors
        </h1>
      </Link>
      <Link
        to="tecrek-visitors"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${
                      isActive("/dashboard/tecrek-visitors")
                        ? "bg-[#6d5d45]"
                        : ""
                    }`}
      >
        <BsPersonVcardFill size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Tecrek Visitors
        </h1>
      </Link>
      <Link
        to="tracktions"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${
                      isActive("/dashboard/tracktions") ||
                      isActive(`/dashboard/tracktions/${id}`)
                        ? "bg-[#6d5d45]"
                        : ""
                    }`}
      >
        <FaMapLocation size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Tracktions
        </h1>
      </Link>
      <Link
        to="ads"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${isActive("/dashboard/ads") ? "bg-[#6d5d45]" : ""}`}
      >
        <FaAd size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>Ads</h1>
      </Link>
      <Link
        to="access-tokens"
        className={`flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b]
                    ${
                      isActive("/dashboard/access-tokens") ||
                      isActive(`/dashboard/access-tokens/${id}`)
                        ? "bg-[#6d5d45]"
                        : ""
                    }`}
      >
        <HiKey size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Access Tokens
        </h1>
      </Link>
      <div>
        {dashboardSideBarOpen ? (
          <div
            onClick={() => setDashboardSideBarOpen(false)}
            className="flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b] c-pointer group"
          >
            <AiOutlineClose size={20} />
            <h1>Hide</h1>
          </div>
        ) : (
          <div
            onClick={() => setDashboardSideBarOpen(true)}
            className="flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b] c-pointer group"
          >
            <MdOutlineArrowForwardIos
              size={20}
              className="group-hover:translate-x-1 d-300"
            />
          </div>
        )}
      </div>
      <div
        onClick={handleLogout}
        className="flex items-center gap-2 w-full h-fit pt-4 pb-3 px-4 whitespace-nowrap text-[16px] transition d-300 t-white hover:bg-[#8f7a5b] c-pointer group"
      >
        <FaPowerOff size={20} />
        <h1 className={`${dashboardSideBarOpen ? "block" : "hidden"}`}>
          Logout
        </h1>
      </div>
    </nav>
  );
}

export default DashboardSidebar;
