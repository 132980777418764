import React, { useContext } from "react";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTheme } from "../../Context/ThemeContext";

function FeaturesItem({ image, title, description }) {
  const { theme } = useTheme();
  const { handleImageError } = useContext(PhotoContext);

  return (
    <div className={`${theme === "dark" && "text-DarkMainText"} text-center`}>
      <div className="mx-auto w-[60px] h-[60px] max-md:w-[40px] max-md:h-[40px] mb-3">
        <img
          className="w-full h-full o-cover"
          src={image}
          alt=""
          onError={handleImageError}
        />
      </div>
      <h1 className="f-bold text-[18px] max-md:text-[14px]">{title}</h1>
      <p className="text-[16px] max-md:text-[12px]">{description}</p>
    </div>
  );
}

export default FeaturesItem;
