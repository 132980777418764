import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function MaterialBoxItem({ isActive, image, title, content, onClick }) {
  const { theme } = useTheme();
  return (
    <div
      onClick={onClick}
      className={`${
        isActive
          ? "border-PrimaryColor text-PrimaryColor cursor-default pointer-events-none"
          : "border-transparent c-pointer"
      } ${
        theme === "dark" && "bg-DarkBoxBg"
      } w-[130px] d-300 border-[2px] flex flex-col items-center h-full min-h-full rounded-[9px] overflow-hidden`}
    >
      <div className="max-w-full max-h-[100px] w-full">
        <img className="w-full h-full o-cover" src={image} alt="" />
      </div>
      <div
        className={`${
          isActive
            ? "text-PrimaryColor"
            : theme === "dark"
            ? "text-DarkMainText/70"
            : "text-SecondText"
        } py-[12px] px-1 w-full`}
      >
        <h1 className="text-[15px] leading-[18px] font-[600] mb-[3px]">
          {title}
        </h1>
        <h2 className="text-[14px] leading-[120%]">{content}</h2>
      </div>
    </div>
  );
}

export default MaterialBoxItem;
