
import React from "react";
import MainButton from "../../../../Shared/Components/MainButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../Context/ThemeContext";
import pic1 from "../../../../assets/imgs/GalleryLandingSection/10.20.jpeg";
import pic2 from "../../../../assets/imgs/GalleryLandingSection/Luxury20.jpeg";
import pic3 from "../../../../assets/imgs/GalleryLandingSection/1.020.jpeg";
import pic4 from "../../../../assets/imgs/GalleryLandingSection/Luxury10.jpeg";

function LandingGallery() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const lng = localStorage.getItem("vividVisionsLang") || "English";

  return (
    <div className="flex items-center gap-[80px] mb-5 mt-5 md:my-20 pt-6 max-md:flex-col-reverse max-md:gap-2 max-md:mb-[40px] ">
      <div
        className={`${
          theme === "dark" && "text-DarkMainText"
        } basis-[50%] max-md:text-center`}
      >
        <h1 className="f-bold text-[50px] md:leading-[4rem] mb-6 max-md:mb-4 max-xl:f-bold max-xl:text-[45px] 2xl:max-w-[500px] max-md:text-[20px]">
          {t(
            "Transform your walls with stunning, one-of-a-kind art from our exclusive collection."
          )}
        </h1>
        <div className="max-md:flex max-md:flex-col max-md:items-center">
          <p className="text-[18px] max-md:text-[14px] mb-6 max-md:mb-4 f-semi-bold">
            {t(
              "Discover unique masterpieces that will elevate your space into a stunning visual experience."
            )}
          </p>
          <div className="w-full md:w-[312px] h-[60px] r-12 mb-[24px]">
            <MainButton
              link={"/art-home"}
              content={t("Shop Now")}
              className="w-full h-full"
            />
          </div>
          <div className="">
            <h1 className="f-bold text-[18px] max-md:text-[14px] inline">
              {t(
                "Browse our collection and bring the beauty of art into your home today!"
              )}
            </h1>
          </div>
        </div>
      </div>
      <div className="basis-[50%] ml-auto max-md:mx-auto">
        <div className="md:ml-auto md:grid md:grid-cols-2 md:gap-[20px]">
          <div
            className={`${
              lng === "Arabic" ? "md:mr-auto" : "md:ml-auto"
            } mb-[20px] md:max-w-[236px] md:max-h-[236px] md:my-auto md:transform md:-translate-y-[100px] max-md:hidden`}
          >
            <img
              className="w-full h-full r-12 max-md:max-h-[340px] max-md:aspect-square o-cover object-top"
              src={pic3}
              alt=""
            />
          </div>
          <div
            className={`mb-[20px] md:max-w-[236px] md:max-h-[236px] md:my-auto md:transform md:-translate-y-[100px] md:hidden`}
          >
            <img
              className="w-full h-full r-12 max-md:max-h-[340px] max-md:aspect-square o-cover object-top"
              src={pic1}
              alt=""
            />
          </div>
          <div className="flex gap-[20px] md:flex-col">
            <div className="hidden md:block basis-[50%] md:max-w-[250px]">
              <img className="w-full h-full r-12" src={pic4} alt="" />
            </div>
            <div className="block md:hidden basis-[50%] md:max-w-[250px]">
              <img className="w-full h-full r-12" src={pic2} alt="" />
            </div>
            <div className="basis-[50%] md:max-w-[350px] max-md:hidden">
              <img className="w-full h-full r-12" src={pic1} alt="" />
            </div>
            <div className="basis-[50%] md:max-w-[350px] md:hidden">
              <img className="w-full h-full r-12" src={pic3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingGallery;

