import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useTheme } from "../../Context/ThemeContext";

function CustomPagination({
  key,
  totalPages,
  currentPage,
  itemPerPage,
  handlePageClick,
  from,
  to,
  total,
  dashMode,
}) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <div className="flex items-center justify-between max-sm:flex-col-reverse">
      {totalPages > 1 && (
        <ReactPaginate
          key={key}
          initialPage={currentPage - 1}
          previousLabel={dashMode ? "Prev" : t("Prev")}
          nextLabel={dashMode ? "Next" : t("Next")}
          breakLabel={"..."}
          pageCount={Math.ceil(totalPages)}
          perPage={itemPerPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={
            "flex flex-wrap items-center py-4 gap-y-5 gap-x-1"
          }
          breakClassName="text-PrimaryColor"
          previousLinkClassName={
            "max-md:text-[12px] px-4 py-2 max-md:px-2 max-md:py-1 bg-PrimaryColor t-white hover:bg-HoverPrimaryColor f-bold d-300 rounded-[20px]"
          }
          nextLinkClassName={
            "max-md:text-[12px] px-4 py-2 max-md:px-2 max-md:py-1 bg-PrimaryColor t-white hover:bg-HoverPrimaryColor f-bold d-300 rounded-[20px]"
          }
          pageLinkClassName={
            "max-md:text-[12px] px-4 py-2 max-md:px-2 max-md:py-1 bg-PrimaryColor t-white hover:bg-HoverPrimaryColor f-bold d-300 rounded-[20px]"
          }
          activeLinkClassName={`${
            theme === "dark" ? "border-DarkMainText" : "border-MainText"
          } max-md:text-[12px] px-4 py-2 max-md:px-2 max-md:py-1 bg-PrimaryColor t-white hover:bg-HoverPrimaryColor f-bold d-300 rounded-[20px] border-2`}
          renderOnZeroPageCount={null}
        />
      )}
      {from && to && total && (
        <div className="text-[14ox] f-semi-bold">
          Showing from {from} to {to} of {total} of results.
        </div>
      )}
    </div>
  );
}

export default CustomPagination;
